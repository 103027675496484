import React from "react";
import styled from "styled-components";
import moment from "moment";
import { useQuery } from "react-query";
import { theme } from "@icg360/design-system";
import PropTypes from "prop-types";
import { userPropTypes } from "../utils/user";
import {
  CenterWrapper,
  DateTime,
  Notes,
  Note,
  Page,
  PageHeader
} from "./shared/Notifications";
import useWhatsNew from "../hooks/useWhatsNew";

const Message = styled.span`
  color: ${theme.colors.text.quiet};
  display: inline-block;
  font-size: 14px;
`;

const WhatsNew = ({ user: { id: userId } }) => {
  const { data: logs = [], isLoading } = useQuery(["getGitLogs"]);

  const { alreadyViewedReleaseNoteVersions } = useWhatsNew({
    shouldMarkAsViewed: true,
    userId
  });

  const filteredLogs = logs?.length
    ? logs.filter(log => moment(log.date).isAfter("2021-12-9"))
    : [];

  if (!isLoading && filteredLogs?.length) {
    return (
      <Page>
        <CenterWrapper>
          <PageHeader>What’s New in IPC Manager</PageHeader>
          <Notes>
            {filteredLogs.map(({ commit, date, message, version }) => (
              <Note
                data-bdd="release-note"
                isNew={!alreadyViewedReleaseNoteVersions.includes(version)}
                key={commit}
              >
                <DateTime date={moment(date).valueOf()} />
                <Message key={message}>{message.replaceAll("-", " ")}</Message>
              </Note>
            ))}
          </Notes>
        </CenterWrapper>
      </Page>
    );
  }
  return (
    <Page>
      <CenterWrapper>
        <PageHeader>What’s New in IPC Manager</PageHeader>
        <Notes>
          <Note>
            <Message>
              Release history not available. Please try again later.
            </Message>
          </Note>
        </Notes>
      </CenterWrapper>
    </Page>
  );
};

WhatsNew.propTypes = {
  user: PropTypes.shape(userPropTypes).isRequired
};

export default WhatsNew;
