import React from "react";
import styled from "styled-components";
import { theme } from "@icg360/design-system";
import PropTypes from "prop-types";
import moment from "moment";

export const Page = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 28px;
`;

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 616px;
`;

export const PageHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 19px;
`;

export const Notes = styled.div`
  border-top: solid 2px ${theme.colors.fill.accent};
  margin-bottom: 40px;
`;

export const Note = styled.div`
  background: ${theme.colors.fill.surface};
  border: 1px solid ${theme.colors.border.default};
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const StyledDateTime = styled.div`
  color: ${theme.colors.text.quiet};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 5px;
`;

export const DateTime = ({ date }) => (
  <StyledDateTime>{moment(date).format("MMMM D, YYYY - h:mma")}</StyledDateTime>
);

DateTime.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export const Title = styled.div`
  color: ${theme.colors.text.quiet};
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 10px;
`;
