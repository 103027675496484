/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */

import { Alert, Form } from "@icg360/ui-toolkit";
import {
  Alert as DSAlert,
  Button,
  Link as DSLink,
  TextInput
} from "@icg360/design-system";
import {
  initTracking,
  LOGIN_SSO_MICROSOFT,
  startTracking,
  ENFORCE_LOGIN_SSO_MICROSOFT,
  track
} from "@package/ipcmgr-toolkit";
import { bool, func, object, shape } from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { configPropTypes } from "../config";
import { login, logout } from "../utils/user";
import ForgotPassword from "./ForgotPassword";
import { initFlagsUnauthorized } from "../utils/feature-flags";
import "./Login.css";

const SSO_FORM = "microsoftSSO";
const SSO_FORM_SELECTOR = `form[name="${SSO_FORM}"]`;
export default class Login extends Component {
  static isSafari() {
    return navigator.vendor === "Apple Computer, Inc.";
  }

  state = {
    username: "",
    password: "",
    isLoading: false,
    error: null,
    success: null,
    flags: {}
  };

  els = {};

  static propTypes = {
    config: shape(configPropTypes).isRequired,
    onLogin: func.isRequired,
    history: object.isRequired,
    showModal: bool.isRequired
  };

  componentDidMount() {
    this.tryAutoFocus();
    this.initFlags();

    const { config } = this.props;
    const url = new URL(window.location.href);
    const errorType = url.searchParams.get("error");

    initTracking(config.mixpanelToken);
    startTracking();

    if (errorType) {
      track("Login error", { Method: "OIDC", ErrorType: errorType });
      let errorText = `OIDC login error: ${errorType}`;
      if (errorType === "NOT_FOUND") {
        errorText = "This user is not set up for Microsoft sign-in";
      }
      this.setState({
        isLoading: false,
        error: errorText
      });
    }
  }

  saveRef = name => ref => {
    this.els[name] = ref;
  };

  tryAutoFocus = (el = "username") => {
    if (this.els[el]) {
      this.els[el].focus();
    }
  };

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { config, onLogin } = this.props;
    const { username, password } = this.state;

    this.setState({
      error: null,
      isLoading: true
    });

    initFlagsUnauthorized(config, {
      key: username.toLowerCase(),
      email: username.toLowerCase()
    }).then(({ client }) => {
      const { [ENFORCE_LOGIN_SSO_MICROSOFT]: enforceSSO } = client.allFlags();
      if (enforceSSO) {
        this.setState(
          {
            isLoading: false,
            error:
              "SageSure employees and contractors must sign in with Microsoft."
          },
          this.tryAutoFocus
        );
        return;
      }
      login({
        username,
        password,
        servicesUrl: config.services.url
      }).then(({ status, ...user }) => {
        const { loginRequiredRole } = config;
        const success = status !== "error";
        const hasLoginRole =
          success &&
          (!loginRequiredRole || user.roles.includes(loginRequiredRole));
        if (hasLoginRole) {
          onLogin(user, this.tryAutoFocus);
        } else {
          track("Login error", { Method: "Traditional" });
          if (success) {
            logout(config.services.url);
          }
          this.setState(
            {
              isLoading: false,
              error:
                "Invalid credentials or system unavailable. Please try again."
            },
            this.tryAutoFocus
          );
        }
      });
    });
  };

  setAlert = err => {
    if (err) {
      this.setState({
        error:
          "Something went wrong. If this problem persists, please contact support@sagesure.com"
      });
    } else {
      this.setState({
        success:
          "An email was sent for your username with a link to reset your password. Problems? Contact support@sagesure.com."
      });
    }
  };

  handleSSO = e => {
    e.preventDefault();
    return document.querySelector(SSO_FORM_SELECTOR).submit();
  };

  initFlags() {
    const { config } = this.props;
    initFlagsUnauthorized(config).then(({ client }) => {
      const flags = client.allFlags();
      this.setState({ flags });
    });
  }

  render() {
    const { config, showModal, history } = this.props;
    const { username, password, isLoading, error, success, flags } = this.state;
    const { customer, customerId, microsoftSSOUrl } = config;
    const showSSO = flags[LOGIN_SSO_MICROSOFT];
    const enforceSSO = flags[ENFORCE_LOGIN_SSO_MICROSOFT];
    const messageLink = (
      <DSLink href="/" onClick={this.handleSSO}>
        sign in
      </DSLink>
    );

    return (
      <main className="login">
        {success && (
          <Alert
            bsStyle="success"
            isGlobal
            autoDismiss
            onDismiss={() => {
              this.setState({ success: null });
            }}
          >
            <p>
              <strong>Success! </strong>
              {success}
            </p>
          </Alert>
        )}
        {error && (
          <Alert
            bsStyle="danger"
            isGlobal
            autoDismiss
            onDismiss={() => {
              this.setState({ error: false });
            }}
          >
            <p data-bdd="login-failed">
              <strong>Error. </strong>
              {error}
            </p>
          </Alert>
        )}
        <div className="login__wrap">
          <div className="login__main">
            <div className="login__logo">
              <img
                alt={`${customer.displayName} logo`}
                src={`/customers/${customerId}/header-logo-glyph.svg`}
              />
            </div>
            {showSSO && (
              <div>
                <Form name={SSO_FORM} method="post" action={microsoftSSOUrl}>
                  <Button
                    class="login-button login-button-sso"
                    data-bdd="login-button-sso"
                    data-track="Login: User clicked Sign In with Microsoft"
                    disabled={isLoading}
                    appearance="secondary"
                    type="submit"
                    fluid
                  >
                    Sign in with Microsoft
                  </Button>
                </Form>
                <div className="separator">OR</div>
              </div>
            )}
            {showSSO && enforceSSO && (
              <div className="enforce-sso-wrapper">
                <DSAlert
                  appearance="info"
                  description={
                    <div>
                      SageSure employees and contractors must {messageLink} with
                      Microsoft.
                    </div>
                  }
                />
              </div>
            )}
            <Form
              className="login__form"
              disabled={isLoading}
              onSubmit={this.handleSubmit}
            >
              <TextInput
                label="Username"
                value={username}
                disabled={isLoading}
                id="username"
                name="username"
                data-bdd="login-username"
                controlId="username"
                onChange={this.handleInputChange}
                inputRef={this.saveRef("username")}
              />
              <TextInput
                label="Password"
                value={password}
                disabled={isLoading}
                id="password"
                name="password"
                data-bdd="login-password"
                onChange={this.handleInputChange}
                controlId="password"
                type="password"
                inputRef={this.saveRef("password")}
              />
              <Button
                appearance="primary"
                type="submit"
                disabled={isLoading || !username || !password}
                data-bdd="login-button"
                data-track="Login: User clicked Sign in"
                fluid
              >
                Sign in
              </Button>
              <Link to="/forgot-password" className="forgot-password">
                Forgot Password?
              </Link>
            </Form>
            {Login.isSafari() && (
              <Alert bsStyle="info" data-bdd="login-safari-alert" hasIcon>
                <p>
                  <strong>We&apos;ve notice you&apos;re using safari.</strong>
                  <br />
                  In order to continue using safari, be sure to uncheck the
                  &apos;Prevent cross-site tracking option&apos; in your
                  preferences.
                  <br />
                  This is in Safari &gt; Preferences &gt; Privacy
                </p>
              </Alert>
            )}
          </div>
        </div>
        {showModal && (
          <ForgotPassword
            config={config}
            history={history}
            setAlert={this.setAlert}
            showModal={showModal}
          />
        )}
      </main>
    );
  }
}
