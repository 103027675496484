/* eslint-disable camelcase */
import moment from "moment";
import { escape } from "lodash";
import PolicyParser from "@package/policy-parser";
import postPolicyNote from "qandp/promises/post-policy-note";
import { UW_CONDITION_TEXT } from "qandp/constants";
import checkForVersionError from "./utils";

// Set Underwriting Conditions
const uwConditions = ({ underwritingConditions }) =>
  underwritingConditions
    .map(
      (cond, i) =>
        `<DataItem name="${UW_CONDITION_TEXT}${i + 1}" value="${cond}"/>`
    )
    .join("\n");

export default async ({
  id,
  serviceUrl,
  policy,
  commit,
  username,
  values,
  reasonCode,
  hideUnderWritingConditions
}) => {
  const {
    version,
    identifiers: { insightPolicyId },
    policyData: { ExpirationDate }
  } = policy;
  const effectiveDate = moment(ExpirationDate.substr(0, 10)).format(
    "YYYY-MM-DD"
  );

  const intervalRequestData = !hideUnderWritingConditions
    ? `<IntervalRequest>
        ${uwConditions(values)}
        </IntervalRequest>`
    : "";

  const reasonCodeXml = reasonCode
    ? `<ReasonCode>${reasonCode.toString().replace("_bop", "")}</ReasonCode>`
    : "";

  const body = `<TransactionRequest schemaVersion="1.4" type="${values.action}">
    <Initiation>
      <Initiator type="user">${username}</Initiator>
    </Initiation>
    <Target>
      <Identifiers>
        <Identifier name="InsightPolicyId" value="${insightPolicyId}"/>
      </Identifiers>
      <SourceVersion>${version}</SourceVersion>
    </Target>
    <EffectiveDate>${effectiveDate}</EffectiveDate>
    ${reasonCodeXml}
    <Comment>${escape(values.comment)}</Comment>
    ${intervalRequestData}
  </TransactionRequest>`;

  const reqOpts = {
    body,
    method: "POST",
    headers: {
      Accept: "application/vnd.ics360.insurancepolicy.2.8+xml",
      "Content-Type": "application/xml; schema=transactionrequest.1.4",
      "X-Commit": commit
    },
    mode: "cors",
    credentials: "include"
  };

  const req = new Request(`${serviceUrl}/policies/${id}`, reqOpts);
  try {
    const resp = await fetch(req);
    if (!resp.ok) {
      const errorMsg = checkForVersionError(resp);
      return Promise.reject(new Error(errorMsg));
    }
    if (values.note)
      await postPolicyNote({
        policyId: id,
        serviceUrl,
        username,
        note: values.note
      });
    const xmlResp = await resp.text();
    return PolicyParser.parse(xmlResp);
  } catch (e) {
    return Promise.reject(e);
  }
};
