import REASON_CODE_ENUM, {
  INSURED_REQUEST_REASON_CODE_ENUMS
} from "./reason-codes";
// This is an issue with the eslint packages.
// They need to be updated to match our newer typescript version
// eslint-disable-next-line
import NON_SAGESURE_CARRIERS from "./non-sagesure-carriers";

const {
  INSURED_REQUEST,
  NONPAYMENT_OF_PREMIUM,
  INSURED_CONVICTED,
  DISCOVERY_OF_FRAUD,
  DISCOVERY_OF_WILLFUL,
  PHYSICAL_CHANGES,
  INCREASE_IN_LIABILITY,
  INCREASE_IN_PROPERTY,
  OVEREXPOSED,
  CHANGE_IN_OCCUPANCY,
  OTHER_UNDERWRITING_REASONS,
  CANCEL_REWRITE,
  CHANGE_IN_OWNERSHIP,
  MISSING_REQUIRED_DOCS,
  INSURED_REQUEST_SHORT_RATE,
  NONPAYMENT_OF_PREMIUM_FLAT,
  REPLACEMENT_POLICY,
  PAYMENT_RECEIVED_AFTER_EFFECTIVE,
  DUE_TO_COMPLIANCE,
  UNDERWRITING_REASON,
  PROPERTY_SOLD,
  BETTER_PRICE,
  UNSATISFACTORY_EXPERIENCE,
  REWRITE_WITH_SAGESURE,
  OTHER,
  REWRITE_WITH_NON_SAGESURE,
  NO_LONGER_CUSTOMER,
  SELF_INSURED,
  PAYMENT_NOT_RECEIVED,
  PAYMENT_RECEIEVED,
  REINSTATEMENT_FLAT_CANCEL,
  RENEWAL_NOT_ACCEPTED,
  RENEWAL_PAYMENT_RECEIVED,
  RISK_INELIGIBLE,
  NONPAYMENT_OF_PREMIUM_FINANCE_CO,
  SUBSTANTIAL_RISK_CHANGE,
  LOSS_OF_REINSURANCE,
  MATERIAL_MISREPRESENTATION,
  SUBSTANTIAL_CONTRACT_BREACH,
  LOSS_CONTROL_INSPECTION,
  FRAUDULENT_ACT,
  BOOK_ROLL,
  INSUFFICIENT_RATE_EXPOSURE,
  DUPLICATE_POLICY
} = REASON_CODE_ENUM;

const SHARED_INSURED_CANCEL_REASONS = [
  {
    label: "Insured purchased a new home in-state",
    value: INSURED_REQUEST_REASON_CODE_ENUMS.NEW_HOME_IN_STATE
  },
  {
    label: "Insured purchased a new out-of-state",
    value: INSURED_REQUEST_REASON_CODE_ENUMS.NEW_HOME_OUT_OF_STATE
  },
  {
    label: "Insured is no longer a homeowner",
    value: INSURED_REQUEST_REASON_CODE_ENUMS.NO_LONGER_HOME_OWNER
  }
];

export const INSURED_CANCEL_REASONS = {
  REWRITTEN_TO_OTHER_SAGESURE_POLICY: "REWRITTEN_TO_OTHER_SAGESURE_POLICY",
  REPLACEMENT_NON_SAGESURE_CARRIER: "REPLACEMENT_NON_SAGESURE_CARRIER",
  SOLD_PROPERTY: "SOLD_PROPERTY",
  NO_LONGER_A_CUSTOMER: "NO_LONGER_A_CUSTOMER",
  SELF_INSURED: "SELF_INSURED",
  REPLACEMENT_POLICY_CREATED_REASONS: "REPLACEMENT_POLICY_CREATED_REASONS",
  CARRIER_INSURED_MOVED_TO: "CARRIER_INSURED_MOVED_TO",
  CONTRIBUTING_FACTORS: "CONTRIBUTING_FACTORS",
  CURRENT_MAILING_ADDRESS: "CURRENT_MAILING_ADDRESS",
  CUSTOMER_DECISION_TO_LEAVE: "CUSTOMER_DECISION_TO_LEAVE",
  CUSTOMER_DECISION_TO_SELF_INSURE: "CUSTOMER_DECISION_TO_SELF_INSURE"
};

export const CANCEL_ACTIONS = {
  Cancellation: "Cancellation",
  BopCancellation: "BopCancellation",
  PendingCancellation: "PendingCancellation",
  BopPendingCancellation: "BopPendingCancellation",
  Reinstatement: "Reinstatement",
  PendingCancellationRescission: "PendingCancellationRescission",
  ODEN_PENDING_CANCELLATION: "ODEN_PENDING_CANCELLATION",
  ODEN_RESCIND_PENDING_CANCELLATION: "ODEN_RESCIND_PENDING_CANCELLATION"
};

export const DERIVED_EFFECTIVE_DATE_CODES = [
  NONPAYMENT_OF_PREMIUM,
  PHYSICAL_CHANGES,
  INCREASE_IN_LIABILITY,
  INCREASE_IN_PROPERTY,
  CHANGE_IN_OCCUPANCY,
  OTHER_UNDERWRITING_REASONS,
  MISSING_REQUIRED_DOCS,
  NONPAYMENT_OF_PREMIUM_FLAT,
  INSUFFICIENT_RATE_EXPOSURE
];

export const ALLOWED_REASONS_UWC = [
  REASON_CODE_ENUM.PHYSICAL_CHANGES,
  REASON_CODE_ENUM.INCREASE_IN_LIABILITY,
  REASON_CODE_ENUM.INCREASE_IN_PROPERTY,
  REASON_CODE_ENUM.OTHER_UNDERWRITING_REASONS,
  // BOP-specific:
  REASON_CODE_ENUM.SUBSTANTIAL_RISK_CHANGE
];

export const INSURED_CANCEL_REASONS_GEICO = [
  { label: "Select Reason", value: "" },
  { label: "Property sold", value: PROPERTY_SOLD },
  {
    label: "Better price/coverage with other carrier",
    value: BETTER_PRICE
  },
  {
    label: "Unsatisfactory experience with SageSure",
    value: UNSATISFACTORY_EXPERIENCE
  },
  {
    label: "Rewrite with SageSure",
    value: REWRITE_WITH_SAGESURE
  },
  { label: "Other", value: OTHER }
];

export const INSURED_CANCEL_OPTIONS = {
  PRIMARY: [
    {
      label: "Rewritten to other SageSure policy",
      value: REWRITE_WITH_SAGESURE
    },
    {
      label: "Replacement policy written with a non-SageSure carrier",
      value: REWRITE_WITH_NON_SAGESURE
    },
    {
      label: "Insured sold the property",
      value: PROPERTY_SOLD
    },
    {
      label: "Insured is no longer a customer",
      value: NO_LONGER_CUSTOMER
    },
    {
      label: "Insured is now self-insuring",
      value: SELF_INSURED
    }
  ],
  SECONDARY: {
    [INSURED_CANCEL_REASONS.REPLACEMENT_POLICY_CREATED_REASONS]: [
      {
        label: "Better price",
        value: INSURED_REQUEST_REASON_CODE_ENUMS.BETTER_PRICE
      },
      {
        label: "Better coverage",
        value: INSURED_REQUEST_REASON_CODE_ENUMS.BETTER_COVERAGE
      },
      {
        label: "Other reasons",
        value: INSURED_REQUEST_REASON_CODE_ENUMS.OTHER_REASONS
      }
    ],
    [INSURED_CANCEL_REASONS.CARRIER_INSURED_MOVED_TO]: NON_SAGESURE_CARRIERS.map(
      carrier => ({
        label: carrier,
        value: carrier
      })
    ),
    [INSURED_CANCEL_REASONS.CONTRIBUTING_FACTORS]: [
      {
        label: "Better price",
        value: INSURED_REQUEST_REASON_CODE_ENUMS.BETTER_PRICE
      },
      {
        label: "Better coverage",
        value: INSURED_REQUEST_REASON_CODE_ENUMS.BETTER_COVERAGE
      },
      {
        label: "Unsatisfactory claims experience",
        value:
          INSURED_REQUEST_REASON_CODE_ENUMS.UNSATISFACTORY_CLAIMS_EXPERIENCE
      },
      {
        label: "Unsatisfactory service experience",
        value:
          INSURED_REQUEST_REASON_CODE_ENUMS.UNSATISFACTORY_SERVICE_EXPERIENCE
      },
      {
        label: "Unsatisfactory underwriting experience",
        value:
          INSURED_REQUEST_REASON_CODE_ENUMS.UNSATISFACTORY_UNDERWRITING_EXPERIENCE
      },
      {
        label: "Other",
        value: INSURED_REQUEST_REASON_CODE_ENUMS.OTHER_FACTOR
      }
    ],
    [INSURED_CANCEL_REASONS.SOLD_PROPERTY]: SHARED_INSURED_CANCEL_REASONS,
    [INSURED_CANCEL_REASONS.CURRENT_MAILING_ADDRESS]: [
      {
        label: "Yes",
        value: INSURED_REQUEST_REASON_CODE_ENUMS.CURRENT_MAILING_ADDRESS_VALID
      },
      {
        label: "No",
        value: INSURED_REQUEST_REASON_CODE_ENUMS.CURRENT_MAILING_ADDRESS_INVALID
      }
    ],
    [INSURED_CANCEL_REASONS.CUSTOMER_DECISION_TO_LEAVE]: [
      ...SHARED_INSURED_CANCEL_REASONS,
      {
        label: "Other",
        value: INSURED_REQUEST_REASON_CODE_ENUMS.OTHER_DECISION_TO_LEAVE
      }
    ],
    [INSURED_CANCEL_REASONS.CUSTOMER_DECISION_TO_SELF_INSURE]: [
      {
        label: "Can no longer afford premiums",
        value: INSURED_REQUEST_REASON_CODE_ENUMS.NO_LONGER_AFFORD_PREMIUMS
      },
      {
        label: "Feels insurance is not worth the value",
        value: INSURED_REQUEST_REASON_CODE_ENUMS.INSURANCE_NOT_WORTH_VALUE
      },
      {
        label: "Other reasons",
        value: INSURED_REQUEST_REASON_CODE_ENUMS.OTHER_SELF_INSURE_REASON
      }
    ]
  }
};

export const MEP_INDICATOR_MAPPING = {
  100: true,
  200: false,
  NULL: false
};

export const REASON_CODE_ENUM_OVERRIDES = {
  [INSURED_REQUEST]: "15"
};

export const STATE_SPECIFIC_EXCLUSIONS = {
  SC: [],
  LA: []
};

export const INSURED_CANCEL_DATA_ITEM_NAMES = {
  [REWRITE_WITH_SAGESURE]: [
    "InsuredCancelReplacementPolicyNumber",
    "InsuredCancelReplacementReason",
    "InsuredCancelReplacementReasonInfo"
  ],
  [REWRITE_WITH_NON_SAGESURE]: [
    "InsuredCancelReplacementPolicyCarrier",
    "InsuredCancelReplacementPolicyFactor",
    "InsuredCancelReplacementPolicyPrice",
    "InsuredCancelReplacementDetail",
    "InsuredCancelReplacementPolicyEffectiveDate"
  ],
  [PROPERTY_SOLD]: [
    "InsuredCancelPropertySold",
    "InsuredCancelMailingAddress",
    "InsuredCancelForwardingAddress",
    "InsuredCancelForwardingDate"
  ],
  [NO_LONGER_CUSTOMER]: [
    "InsuredCancelDecideLeave",
    "InsuredCancelDecideLeaveInfo",
    "InsuredCancelAgencyHelp",
    "InsuredCancelCustomerLeaveEffectiveDate"
  ],
  [SELF_INSURED]: [
    "InsuredCancelSelfInsuredReason",
    "InsuredCancelSelfInsuredReasonDetail",
    "InsuredCancelSelfInsuredEffectiveDate"
  ]
};

export const REASON_CODES = {
  [INSURED_REQUEST]: "Insured request",
  [NONPAYMENT_OF_PREMIUM]: "Nonpayment of premium",
  [INSURED_CONVICTED]: "Insured convicted of crime",
  [DISCOVERY_OF_FRAUD]: "Discovery of fraud or material misrepresentation",
  [DISCOVERY_OF_WILLFUL]: "Discovery of willful or reckless acts of omissions",
  [PHYSICAL_CHANGES]: "Physical changes in the property ",
  [INCREASE_IN_LIABILITY]:
    "Increase in liability hazards beyond what is normally accepted",
  [INCREASE_IN_PROPERTY]:
    "Increase in property hazards beyond what is normally accepted",
  [OVEREXPOSED]: "Overexposed in area where risk is located",
  [CHANGE_IN_OCCUPANCY]: "Change in occupancy status",
  [OTHER_UNDERWRITING_REASONS]: "Other underwriting reasons",
  [CANCEL_REWRITE]: "Cancel/Rewrite",
  [CHANGE_IN_OWNERSHIP]: "Change in ownership",
  [MISSING_REQUIRED_DOCS]: "Missing required documentation",
  [INSURED_REQUEST_SHORT_RATE]: "Insured Request (Short-Rate)",
  [NONPAYMENT_OF_PREMIUM_FLAT]: "Nonpayment of premium - flat",
  [REPLACEMENT_POLICY]: "Replacement Policy Placed with other SageSure Carrier",
  [PAYMENT_RECEIVED_AFTER_EFFECTIVE]:
    "Payment received after effective date of cancellation",
  [DUE_TO_COMPLIANCE]: "Due to compliance or legal reasons",
  [UNDERWRITING_REASON]: "Underwriting reason for cancellation resolved",
  [PAYMENT_NOT_RECEIVED]:
    "Payment not received - Underwriting cancellation to be issued",
  [PAYMENT_RECEIEVED]: "Payment received",
  [REINSTATEMENT_FLAT_CANCEL]: "Reinstatement - Flat Cancel",
  [RENEWAL_NOT_ACCEPTED]: "Renewal Not Accepted",
  [RENEWAL_PAYMENT_RECEIVED]: "Renewal Payment Received",
  [BOOK_ROLL]: "Book Roll",
  [INSUFFICIENT_RATE_EXPOSURE]: "Insufficient Rate for the Exposure",
  [DUPLICATE_POLICY]: "Duplicate Policy",
  // BOP-specific:
  [NONPAYMENT_OF_PREMIUM_FINANCE_CO]:
    "Nonpayment of premium to Premium Finance Co",
  [RISK_INELIGIBLE]: "Risk is ineligible",
  [SUBSTANTIAL_RISK_CHANGE]: "Substantial change in the risk assumed",
  [LOSS_OF_REINSURANCE]: "Loss of our reinsurance",
  [MATERIAL_MISREPRESENTATION]:
    "Material Misrepresentation of fact which, if known to us, would have caused us not to issue policy",
  [SUBSTANTIAL_CONTRACT_BREACH]:
    "Substantial breaches of contractual duties, conditions, or warranties",
  [LOSS_CONTROL_INSPECTION]: "Loss Control Inspection",
  [FRAUDULENT_ACT]: "Fraudulent Act"
};

// Baseline option sets: see buildReasonCodeOptions() for overrides
export const REASON_CODE_OPTIONS = {
  [CANCEL_ACTIONS.Cancellation]: [
    DISCOVERY_OF_FRAUD,
    NONPAYMENT_OF_PREMIUM_FLAT,
    REPLACEMENT_POLICY,
    RENEWAL_NOT_ACCEPTED,
    INSURED_REQUEST,
    CANCEL_REWRITE
  ],
  [CANCEL_ACTIONS.BopCancellation]: [
    DISCOVERY_OF_FRAUD,
    NONPAYMENT_OF_PREMIUM_FLAT,
    NONPAYMENT_OF_PREMIUM_FINANCE_CO,
    REPLACEMENT_POLICY,
    RENEWAL_NOT_ACCEPTED,
    INSURED_REQUEST,
    CANCEL_REWRITE,
    RISK_INELIGIBLE
  ],
  [CANCEL_ACTIONS.PendingCancellation]: [
    NONPAYMENT_OF_PREMIUM,
    PHYSICAL_CHANGES,
    INCREASE_IN_LIABILITY,
    INCREASE_IN_PROPERTY,
    OVEREXPOSED,
    CHANGE_IN_OCCUPANCY,
    OTHER_UNDERWRITING_REASONS,
    CHANGE_IN_OWNERSHIP,
    MISSING_REQUIRED_DOCS,
    NONPAYMENT_OF_PREMIUM_FLAT
  ],
  [CANCEL_ACTIONS.BopPendingCancellation]: [
    NONPAYMENT_OF_PREMIUM,
    NONPAYMENT_OF_PREMIUM_FINANCE_CO,
    SUBSTANTIAL_RISK_CHANGE,
    INCREASE_IN_LIABILITY,
    INCREASE_IN_PROPERTY,
    LOSS_OF_REINSURANCE,
    CHANGE_IN_OCCUPANCY,
    OTHER_UNDERWRITING_REASONS,
    CHANGE_IN_OWNERSHIP,
    MISSING_REQUIRED_DOCS,
    NONPAYMENT_OF_PREMIUM_FLAT,
    MATERIAL_MISREPRESENTATION,
    SUBSTANTIAL_CONTRACT_BREACH,
    INSURED_CONVICTED,
    LOSS_CONTROL_INSPECTION,
    FRAUDULENT_ACT
  ],
  [CANCEL_ACTIONS.PendingCancellationRescission]: [
    PAYMENT_RECEIEVED,
    UNDERWRITING_REASON,
    INSURED_REQUEST,
    PAYMENT_NOT_RECEIVED
  ],
  [CANCEL_ACTIONS.Reinstatement]: [
    UNDERWRITING_REASON,
    DUE_TO_COMPLIANCE,
    PAYMENT_RECEIVED_AFTER_EFFECTIVE,
    REINSTATEMENT_FLAT_CANCEL,
    RENEWAL_PAYMENT_RECEIVED
  ]
};

// TODO remove these?
REASON_CODE_OPTIONS[CANCEL_ACTIONS.ODEN_PENDING_CANCELLATION] = [
  ...REASON_CODE_OPTIONS[CANCEL_ACTIONS.PendingCancellation]
];

REASON_CODE_OPTIONS[CANCEL_ACTIONS.ODEN_RESCIND_PENDING_CANCELLATION] = [
  ...REASON_CODE_OPTIONS[CANCEL_ACTIONS.PendingCancellationRescission]
];
