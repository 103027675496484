import React, { useContext, useState, useRef } from "react";
import { QPContext } from "qandp/context";
import {
  QANDP_SEND_EPAY_LINK_BUTTON,
  QANDP_INVOICE_AMOUNT_PAYMENT_OPTION,
  ViewWrapper
} from "@package/ipcmgr-toolkit";
import PT from "prop-types";
import {
  Alert,
  Modal,
  Icon,
  ControlLabel,
  InputGroup,
  FormGroup
} from "@icg360/ui-toolkit";
import {
  Button,
  TextInput,
  RadioGroup,
  Radio,
  theme
} from "@icg360/design-system";
import MaskedInput, { conformToMask } from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { useMutation } from "react-apollo";
import { Formik, Form } from "formik";
import classNames from "classnames";
import styled from "styled-components";
import SEND_PAYMENT_LINK_EMAIL from "qandp/graphql/send-payment-link-email.graphql"; // TODO handle reuse
import SEND_PAYMENT_LINK_TEXT from "qandp/graphql/send-payment-link-text.graphql"; // TODO handle reuse
import postPolicyNote from "qandp/promises/post-policy-note";

const ButtonsContainer = styled.div`
  display: inline-flex;
  button {
    margin: 0 8px;
  }
`;

const ErrorText = styled.span`
  display: block;
  color: ${theme.colors.text.danger};
  height: 21px;
  margin: 5px 0 10px 0;
`;

const phoneMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  integerLimit: 5
});

const CONTACT_METHODS = {
  EMAIL: "email",
  PHONE: "phone"
};

const AMOUNT_TYPES = {
  TOTAL: "Total",
  INVOICE: "Invoice",
  MIN: "Min",
  OTHER: "Other"
};

const EpayButton = ({
  className,
  insuredEmail,
  insuredPhone,
  minAmountDue,
  invoiceAmount,
  totalBalance,
  totalPremium,
  policyId,
  policyState,
  username,
  service,
  refetchPolicy
}) => {
  const conformedPhoneMask = conformToMask(insuredPhone, phoneMask);

  const amountTotal =
    policyState === "CANCELLEDPOLICY" ? totalPremium : totalBalance;

  const classes = classNames("", className, {
    hide: amountTotal <= 0
  });

  const { flags } = useContext(QPContext);

  const formikRef = useRef();

  const formatMoneyValue = value =>
    `$${(parseFloat(value) || 0).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;

  const [showModal, setShowModal] = useState(false);
  const [{ alertStyle, alertHeader, alertContent }, setAlert] = useState({});

  const handleShow = () => {
    setShowModal(true);
  };

  const handleHide = () => {
    setShowModal(false);
  };

  const validateForm = values => {
    const errors = {};
    const emailRegExp = /\S+@\S+\.\S+/;
    let { amountOther } = values;

    if (typeof amountOther === "string") {
      amountOther = parseFloat(amountOther.replace(/,/g, ""));
    }

    if (
      values.contactMethod === CONTACT_METHODS.EMAIL &&
      !emailRegExp.test(values.email)
    ) {
      errors.email = "Please enter a valid email address.";
    }

    if (
      values.contactMethod === CONTACT_METHODS.PHONE &&
      values.phone.length < 14
    ) {
      errors.phone = "Please enter a 10 digit phone number.";
    }

    if (
      values.amountType === AMOUNT_TYPES.INVOICE &&
      values.amountInvoice < 1
    ) {
      errors.amountInvoice = "The payment amount must be greater than $0.";
    }

    if (values.amountType === AMOUNT_TYPES.MIN && values.amountMin < 1) {
      errors.amountMin = "The payment amount must be greater than $0.";
    }

    if (
      values.amountType === AMOUNT_TYPES.OTHER &&
      values.amountOther >= 50000
    ) {
      errors.amountOther =
        "The payment amount can not be greater than $49,999.99.";
    }

    if (values.amountType === AMOUNT_TYPES.OTHER && amountOther < 1) {
      errors.amountOther = "The payment amount must be greater than $0.";
    }

    if (
      values.amountType === AMOUNT_TYPES.OTHER &&
      amountOther < values.amountMin
    ) {
      errors.amountOther =
        "The payment amount must be greater than the minimum amount due.";
    }

    if (
      values.amountType === AMOUNT_TYPES.OTHER &&
      amountOther > values.amountTotal
    ) {
      errors.amountOther =
        "The payment amount must be less than the total balance.";
    }

    return errors;
  };

  const postNote = () => {
    const values = formikRef?.current?.values;

    const isEmail = values.contactMethod === CONTACT_METHODS.EMAIL;
    const amount = values[`amount${values.amountType}`];

    let amountFormatted;

    if (typeof amount === "number") {
      amountFormatted = formatMoneyValue(amount);
    } else {
      amountFormatted = `$${amount}`;
    }

    const note = `ePay Link submitted
    ${isEmail ? "Email address:" : "Text message:"} ${
      isEmail ? values.email : values.phone
    }
    Amount: ${amountFormatted}`;

    postPolicyNote({
      policyId,
      serviceUrl: `${service.url}/${service.basePath}`,
      username,
      note
    });
  };

  const [sendPaymentLink] = useMutation(
    formikRef?.current?.values.contactMethod === CONTACT_METHODS.EMAIL
      ? SEND_PAYMENT_LINK_EMAIL
      : SEND_PAYMENT_LINK_TEXT,
    {
      onCompleted: () => {
        handleHide();
        setAlert({
          alertStyle: "success",
          alertHeader: "Success!",
          alertContent: "The ePay link was successfully sent."
        });
        postNote();
        refetchPolicy();
      },
      onError: () => {
        handleHide();
        setAlert({
          alertStyle: "danger",
          alertHeader: "Error!",
          alertContent: "Something went wrong sending the ePay link."
        });
      }
    }
  );

  const handleSubmit = values => {
    sendPaymentLink({
      variables: {
        policyId,
        amount:
          values.amountType === AMOUNT_TYPES.OTHER
            ? // eslint-disable-next-line radix
              parseInt(
                values[`amount${values.amountType}`].replace(/,/g, ""),
                10
              )
            : values[`amount${values.amountType}`],
        target:
          values.contactMethod === CONTACT_METHODS.EMAIL
            ? values.email
            : values.phone
      }
    });
  };

  return (
    <ViewWrapper flag={flags[QANDP_SEND_EPAY_LINK_BUTTON]}>
      <Button size="sm" onClick={handleShow} className={classes}>
        Send ePay Link
      </Button>

      <Modal show={showModal} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>Send ePay Link</Modal.Title>
        </Modal.Header>
        <Formik
          innerRef={formikRef}
          initialValues={{
            contactMethod: CONTACT_METHODS.PHONE,
            amountType: AMOUNT_TYPES.TOTAL,
            amountTotal: parseFloat(amountTotal.replace(/,/g, "")),
            amountInvoice: parseFloat(invoiceAmount.replace(/,/g, "")),
            amountMin: parseFloat(minAmountDue.replace(/,/g, "")),
            amountOther: 0,
            email: insuredEmail,
            phone: conformedPhoneMask.conformedValue
          }}
          validate={validateForm}
          validateOnBlur
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            setFieldValue,
            isSubmitting,
            touched
          }) => (
            <Form>
              <Modal.Body>
                <div className="epay__info">
                  <Icon className="epay__info__icon" name="info" size="small" />
                  <p className="epay__info__text">
                    This is for sending an email or text message link to the
                    policyholder in order to facilitate completing payment on
                    their account.
                  </p>
                </div>

                <FormGroup>
                  <RadioGroup
                    defaultValue="phone"
                    label="Method of Contact"
                    name="contactMethod"
                    onChange={value => setFieldValue("contactMethod", value)}
                    onBlur={handleBlur}
                    inline
                  >
                    <Radio value={CONTACT_METHODS.PHONE}>Text Message</Radio>
                    <Radio value={CONTACT_METHODS.EMAIL}>Email</Radio>
                  </RadioGroup>
                </FormGroup>

                <FormGroup
                  className={errors.email || errors.phone ? "has-error" : ""}
                >
                  {values.contactMethod === CONTACT_METHODS.PHONE ? (
                    <>
                      <ControlLabel
                        className="epay__group__label"
                        htmlFor="phone"
                      >
                        Phone Number
                      </ControlLabel>
                      <MaskedInput
                        autoComplete="off"
                        className="form-control"
                        id="phone"
                        type="phone"
                        name="phone"
                        mask={phoneMask}
                        guide={false}
                        showMask
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.phone && (
                        <ErrorBlock value="phone" errors={errors} />
                      )}
                    </>
                  ) : (
                    <TextInput
                      label="Email Address"
                      autoComplete="off"
                      id="email"
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.email && touched.email}
                      errorMessage={errors.email}
                    />
                  )}
                </FormGroup>

                <FormGroup
                  className={
                    errors.amountInvoice ||
                    errors.amountMin ||
                    errors.amountOther
                      ? "has-error"
                      : ""
                  }
                >
                  <RadioGroup
                    defaultValue={AMOUNT_TYPES.TOTAL}
                    label="Payment Amount"
                    name="amountType"
                    onChange={value => setFieldValue("amountType", value)}
                    onBlur={handleBlur}
                    inline
                  >
                    <div className="epay__group__options">
                      <div>
                        <Radio value={AMOUNT_TYPES.TOTAL}>Total Balance</Radio>
                        <span
                          className={
                            values.amountType === AMOUNT_TYPES.TOTAL
                              ? "is-selected"
                              : ""
                          }
                        >
                          ({formatMoneyValue(amountTotal)})
                        </span>
                      </div>
                      <ViewWrapper
                        flag={flags[QANDP_INVOICE_AMOUNT_PAYMENT_OPTION]}
                      >
                        <div>
                          <Radio value={AMOUNT_TYPES.INVOICE}>
                            Invoice Amount
                          </Radio>
                          <span
                            className={
                              values.amountType === AMOUNT_TYPES.INVOICE
                                ? "is-selected"
                                : ""
                            }
                          >
                            ({formatMoneyValue(invoiceAmount)})
                          </span>
                        </div>
                      </ViewWrapper>
                      <div>
                        <Radio value={AMOUNT_TYPES.MIN}>
                          Minimum Amount Due
                        </Radio>
                        <span
                          className={
                            values.amountType === AMOUNT_TYPES.MIN
                              ? "is-selected"
                              : ""
                          }
                        >
                          ({formatMoneyValue(minAmountDue)})
                        </span>
                      </div>
                      <div>
                        <Radio value={AMOUNT_TYPES.OTHER}>Other Amount</Radio>
                        <span
                          className={
                            values.amountType === AMOUNT_TYPES.OTHER
                              ? "is-selected"
                              : ""
                          }
                        >
                          <InputGroup>
                            <InputGroup.Addon>$</InputGroup.Addon>
                            <MaskedInput
                              data-bdd="amountOther"
                              autoComplete="off"
                              className="form-control"
                              type="text"
                              name="amountOther"
                              mask={currencyMask}
                              guide={false}
                              placeholder=""
                              value={values.amountOther}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onFocus={() =>
                                setFieldValue("amountType", AMOUNT_TYPES.OTHER)
                              }
                            />
                          </InputGroup>
                        </span>
                      </div>
                    </div>
                  </RadioGroup>
                  {values.amountType === AMOUNT_TYPES.INVOICE && (
                    <ErrorBlock value="amountInvoice" errors={errors} />
                  )}
                  {values.amountType === AMOUNT_TYPES.MIN && (
                    <ErrorBlock value="amountMin" errors={errors} />
                  )}
                  {values.amountType === AMOUNT_TYPES.OTHER && (
                    <ErrorBlock value="amountOther" errors={errors} />
                  )}
                </FormGroup>
              </Modal.Body>

              <Modal.Footer>
                <ButtonsContainer>
                  <Button appearance="neutral" onClick={handleHide}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    data-bdd="epaySubmitButton"
                    disabled={isSubmitting}
                  >
                    Send Link
                  </Button>
                </ButtonsContainer>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      {alertHeader && (
        <Alert
          bsStyle={alertStyle}
          isGlobal
          onDismiss={() => setAlert({})}
          autoDismiss
        >
          <p>
            <strong>{alertHeader} </strong>
            {alertContent}
          </p>
        </Alert>
      )}
    </ViewWrapper>
  );
};

const ErrorBlock = ({ errors, value }) => {
  return <>{errors[value] && <ErrorText>{errors[value]}</ErrorText>}</>;
};

EpayButton.defaultProps = {
  className: "",
  insuredEmail: "",
  insuredPhone: "",
  minAmountDue: "",
  invoiceAmount: "",
  totalBalance: "",
  totalPremium: "",
  policyId: "",
  policyState: "",
  username: "",
  service: {}
};

EpayButton.propTypes = {
  className: PT.string,
  insuredEmail: PT.string,
  insuredPhone: PT.string,
  minAmountDue: PT.string,
  invoiceAmount: PT.string,
  totalBalance: PT.string,
  totalPremium: PT.string,
  policyId: PT.string,
  policyState: PT.string,
  username: PT.string,
  service: PT.object,
  refetchPolicy: PT.func.isRequired
};

ErrorBlock.propTypes = {
  errors: PT.object.isRequired,
  value: PT.string.isRequired
};

export default EpayButton;
