import { debounce } from "lodash";
import mixpanel from "mixpanel-browser";
import * as FullStory from "@fullstory/browser";

let hasInitialized = false;
let enabled = false;

export const track = (eventName, json = {}) => {
  if (enabled) {
    /* eslint-disable no-underscore-dangle */
    const isFullstoryInitialized = !!window._fs_initialized;
    const isFullstoryInDevMode = !!window._fs_dev_mode;

    const fullstorySessionURL =
      isFullstoryInitialized &&
      !isFullstoryInDevMode &&
      FullStory.getCurrentSessionURL(true);

    mixpanel.track(eventName, {
      ...json,
      "Window Width": window.innerWidth,
      "Window Height": window.innerHeight,
      fullstorySessionURL
    });
  }
};

const configureUser = ({
  id,
  // rights,
  roles
  // email,
  // fullname,
  // alc,
  // affiliation
}) => {
  mixpanel.identify(id);
  mixpanel.people.set({
    // TODO: when AuthJS sends more data from the user login, relay that info here
    // $email: email,
    // $name: fullname,
    // ALC: alc,
    // Affiliation: affiliation,
    Roles: roles.join(", ")
  });
};

const onClick = ({ target }) => {
  const element = target.closest("[data-track]");
  if (!element) return;
  const eventName = element.getAttribute("data-track");
  const rawData = element.getAttribute("data-track-json");
  if (rawData) {
    try {
      const json = JSON.parse(rawData);
      track(eventName, json);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn("Malformed JSON in data-track-json attribute", e);
      track(eventName);
    }
  } else {
    track(eventName);
  }
};

const lastValTyped = {};
const onKeyup = debounce(({ target }) => {
  const eventName = target.getAttribute("data-track-typing");
  if (!eventName) return;
  const { value } = target;
  if (lastValTyped[eventName] === value) return;
  lastValTyped[eventName] = value;
  if (value) {
    track(eventName, {
      "Typing Value": value
    });
  }
}, 2000);

const onChange = ({ target }) => {
  const eventName = target.getAttribute("data-track-change");
  if (!eventName) return;
  if (target.tagName === "SELECT") {
    track(eventName, {
      Selection: target.value
    });
    return;
  }
  const { type, checked } = target;
  if (type === "checkbox") {
    track(eventName, {
      "Checkbox Value": checked
    });
  }
  if (type === "radio" && checked) {
    track(eventName);
  }
};

const listen = () => {
  document.addEventListener("click", onClick);
  document.addEventListener("keyup", onKeyup);
  document.addEventListener("change", onChange);
};

const unlisten = () => {
  document.removeEventListener("click", onClick);
  document.removeEventListener("keyup", onKeyup);
  document.removeEventListener("change", onChange);
};

export const initTracking = token => {
  if (token) {
    hasInitialized = true;
    mixpanel.init(token, {
      track_pageview: true,
      persistence: "localStorage"
    });
  }
};

export const startTracking = user => {
  if (hasInitialized) {
    enabled = true;
    if (user) configureUser(user);
    listen();
  }
};

export const stopTracking = () => {
  if (enabled) {
    enabled = false;
    unlisten();
  }
};
