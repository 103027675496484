/** Used to map characters to HTML entities. */
const htmlEscapes = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#39;"
};

/** Used to map HTML entities to characters. */
const htmlUnescapes = {
  "&amp;": "&",
  "&lt;": "<",
  "&gt;": ">",
  "&quot;": '"',
  "&#39;": "'"
};

function basePropertyOf(object) {
  return key => {
    return object == null ? undefined : object[key];
  };
}

/** Used to match HTML entities and HTML characters. */
const reEscapedHtml = /&(?:amp|lt|gt|quot|#39);/g;
const reUnescapedHtml = /[&<>"']/g;
const reHasEscapedHtml = RegExp(reEscapedHtml.source);
const reHasUnescapedHtml = RegExp(reUnescapedHtml.source);

const escapeHtmlChar = basePropertyOf(htmlEscapes);
const unescapeHtmlChar = basePropertyOf(htmlUnescapes);

export const charEscape = string => {
  return string && reHasUnescapedHtml.test(string)
    ? string.replace(reUnescapedHtml, escapeHtmlChar)
    : string;
};

export const charUnescape = string => {
  return string && reHasEscapedHtml.test(string)
    ? string.replace(reEscapedHtml, unescapeHtmlChar)
    : string;
};
