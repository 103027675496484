export default ({ policyId, serviceUrl, parser }) =>
  fetch(`${serviceUrl}/policies/${policyId}`, {
    method: "GET",
    headers: {
      "X-ICG-Client": "true"
    },
    mode: "cors",
    credentials: "include"
  })
    .then(res => {
      if (!res.ok) throw Error("Unable to retrieve policy xml.");
      return res.text();
    })
    .then(xmlStr =>
      parser
        ? parser(xmlStr)
        : new window.DOMParser().parseFromString(xmlStr, "application/xml")
    );
