import { createContext } from "react";

export const TabContextActions = {
  NotesAndDocuments: "NotesAndDocuments",
  AddNoteDropdown: "AddNoteDropdown",
  AddNoteModal: "AddNoteModal",
  AddTaskNote: "AddTaskNote",
  RecentActivity: "RecentActivity",
  RecordALoss: "RecordALoss",
  MainSidebar: "MainSidebar",
  SecondarySidebar: "SecondarySidebar"
};

export const TabContext = createContext();
