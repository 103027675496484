const checkForVersionError = resp => {
  let errorMsg = "Unable to perform action.";
  if (resp.status === 409) {
    errorMsg =
      'The request failed because the policy version is out-of-date. Click "Refresh policy version" to update.';

    setTimeout(() => {
      const refreshBtn = document.getElementById("version-error-refresh");
      if (refreshBtn) {
        refreshBtn.style.display = "block";
      }
    }, 150);
  } else {
    const refreshMsg = document.getElementById("version-refresh-success");
    if (refreshMsg) {
      refreshMsg.style.display = "none";
    }
  }
  return errorMsg;
};

export default checkForVersionError;
