import { IAT_CARRIER_GROUP_IDS, DOCUMENT_GROUP_MAPPING } from "qandp/constants";

export { default as cachePromises } from "./cache-promises";
export { default as getBase64 } from "./getBase64";
export * from "./indicators";
export { default as parseNotes } from "./parseNotes";
export { default as findVocabValue } from "./findVocabValue";
export { default as formatMoneyValue } from "./formatMoneyValue";
export { default as getParentChildInfo } from "./getParentChildInfo";
export { default as determinePolicyLinks } from "./determinePolicyLinks";

export const displayEligibilityStatus = ({
  DisplayEligibilityStatus,
  EligibilityStatus
}) => {
  const statusVals = {
    "0": "N/A",
    "100": "Elite",
    "200": "Preferred"
  };

  return DisplayEligibilityStatus || statusVals[EligibilityStatus || "0"];
};

export const isIAT = groupId => IAT_CARRIER_GROUP_IDS[groupId];

export const sortDocuments = documents =>
  [...documents].sort(
    (doc1, doc2) => (doc2.lastUpdated || "").localeCompare(doc1.lastUpdated) // sorts in descending order
  );

// groups documents like {GroupName: [doc1, doc2], Attachments: [attachment], General: [leftoverDocuments]}
export const generateGroupedDocumentsDynamic = (
  documents,
  attachments,
  documentGroupMapping
) => {
  let sortedDocs = sortDocuments(documents);
  const groups = Object.entries(documentGroupMapping).reduce(
    (obj, [name, group]) => {
      const regex = new RegExp(group.join("|"), "i");
      sortedDocs = sortedDocs.filter(doc => {
        if (!regex.test(doc.subtype)) return true;
        /* eslint-disable no-param-reassign */
        (obj[name] = obj[name] || []).push(doc);
        return false;
      });
      return obj;
    },
    {}
  );
  // General is docs not used in other groups
  if (sortedDocs && sortedDocs.length) {
    groups.General = sortedDocs;
  }
  if (attachments && attachments.length) {
    // maps attachments object to documents object schema
    const mappedAttachments = attachments.map(
      ({ id, href, name, timeStamp }) => ({
        id,
        label: name,
        lastUpdated: timeStamp,
        sourceUrl: href
      })
    );
    groups.Attachments = sortDocuments(mappedAttachments);
  }
  return groups;
};

export const generateGroupedDocuments = (documents, attachments) =>
  generateGroupedDocumentsDynamic(
    documents,
    attachments,
    DOCUMENT_GROUP_MAPPING
  );

// groups items like [[item1, item4], [item2, item5], [item3]]
export const arrayToTwoColumns = array =>
  array.reduce((result, item, i, arr) => {
    if (i >= arr.length / 2) {
      return result;
    }
    const col2 = arr[Math.ceil(arr.length / 2) + i];
    // eslint-disable-next-line no-param-reassign
    result[i] = col2 ? [item, col2] : [item];

    return result;
  }, []);

export const isWholesalePolicy = systemOfRecord => {
  return systemOfRecord === "pxternal";
};
