import { Auth } from "@icg360/auth-js";
import { arrayOf, string } from "prop-types";
import { hasRole } from "@package/ipcmgr-toolkit";

export const userPropTypes = {
  id: string.isRequired,
  roles: arrayOf(string).isRequired
};

export const login = ({ username, password, servicesUrl }) => {
  const authClient = new Auth({
    servicesUrl
  });
  return authClient
    .login(username, password)
    .then(res => res.identity)
    .catch(({ message }) => ({ status: "error", message }));
};

export const logout = servicesUrl => {
  const authClient = new Auth({
    servicesUrl
  });
  return authClient.logout();
};

export const checkSession = servicesUrl => {
  const authClient = new Auth({
    servicesUrl
  });
  return authClient
    .identify()
    .then(res => res.identity)
    .catch(() => null);
};

export const hasModuleAccess = (user, module, flags) =>
  module.filter(({ flag, roles }) => {
    if (!flag || flags[flag]) {
      return true;
    }
    if (roles && roles.length !== 0) {
      return hasRole(user.roles, ...roles);
    }
    return false;
  });

export const forgotPassword = (username, services) => {
  const requestData = {
    username,
    client: "ipcmanager"
  };
  const { userservices } = services;

  const url = `${userservices.url}/${userservices.basePath}/requestReset`;
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(requestData),
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  })
    .then(res => res.text())
    .then(data => (data ? JSON.parse(data) : {}))
    .catch(error => error);
};

export const updatePassword = (
  username,
  currentPassword,
  newPassword,
  { services }
) => {
  const { userservices } = services;
  const body = {
    username,
    oldpassword: currentPassword,
    newpassword: newPassword
  };

  return fetch(`${userservices.url}/${userservices.basePath}/updatePassword`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  })
    .then(res => res.text())
    .then(data => (data ? JSON.parse(data) : {}))
    .catch(error => error);
};

export const resetPassword = (newPassword, { services }) => {
  const { userservices } = services;

  const urlParams = window.location.search
    ? new URLSearchParams(window.location.search)
    : null;

  if (urlParams && urlParams.get("token") && urlParams.get("username")) {
    const body = {
      username: urlParams.get("username"),
      token: urlParams.get("token"),
      newpassword: newPassword
    };

    return fetch(`${userservices.url}/${userservices.basePath}/resetPassword`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    })
      .then(res => res.text())
      .then(data => (data ? JSON.parse(data) : {}))
      .catch(error => error);
  }

  throw Error("Missing password reset token.");
};
