var POLICY_RELATIONSHIPS = {
    PARENT: "PARENT",
    CHILD: "CHILD",
    RELATED: "RELATED"
};
export default (function (policiesInfo, showParentPolicyLinks) {
    var parentPolicyInfo = policiesInfo.parentPolicyInfo, childPolicyInfo = policiesInfo.childPolicyInfo;
    if ((showParentPolicyLinks && (parentPolicyInfo === null || parentPolicyInfo === void 0 ? void 0 : parentPolicyInfo.id)) && (childPolicyInfo === null || childPolicyInfo === void 0 ? void 0 : childPolicyInfo.id)) {
        return POLICY_RELATIONSHIPS.RELATED;
    }
    else if ((showParentPolicyLinks && (parentPolicyInfo === null || parentPolicyInfo === void 0 ? void 0 : parentPolicyInfo.id)) && !(childPolicyInfo === null || childPolicyInfo === void 0 ? void 0 : childPolicyInfo.id)) {
        return POLICY_RELATIONSHIPS.PARENT;
    }
    else if (!(parentPolicyInfo === null || parentPolicyInfo === void 0 ? void 0 : parentPolicyInfo.id) && (childPolicyInfo === null || childPolicyInfo === void 0 ? void 0 : childPolicyInfo.id)) {
        return POLICY_RELATIONSHIPS.CHILD;
    }
    else {
        return null;
    }
});
