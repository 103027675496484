export default ({ vocabPath, serviceUrl }) => {
  return fetch(`${serviceUrl}/${vocabPath}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json"
    }
  }).then(res => {
    if (!res.ok) throw Error("Unable to retrieve vocab.");
    return res.json();
  });
};
