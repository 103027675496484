var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { REQUEST_ERROR_MESSAGE } from "../constants";
export function getProducersEngagement(_a) {
    return __awaiter(this, arguments, void 0, function (_b) {
        var _c, column, direction, formatColumnParameter, url, _d, input, options_1, formatSearchLockParameter, response, producerPulseData, error_1;
        var quarterID = _b.quarterID, serviceUrl = _b.serviceUrl, page = _b.page, queryParams = _b.queryParams;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _c = queryParams.sort, column = _c.column, direction = _c.direction;
                    formatColumnParameter = column
                        .replace(/([a-z])([A-Z])/g, "$1_$2")
                        .toLowerCase();
                    url = "".concat(serviceUrl, "/reports/dataset/").concat(quarterID, "?pageSize=100&pageNumber=").concat(page, "&orderBy=").concat(formatColumnParameter, "&orderType=").concat(direction);
                    if (queryParams.search) {
                        _d = queryParams.search, input = _d.input, options_1 = _d.options;
                        formatSearchLockParameter = (function () {
                            var optionValues = options_1.map(function (option) { return option.value; });
                            return ["1", "2", "3"]
                                .map(function (option) { return (optionValues.includes(option) ? "1" : "0"); })
                                .join(",");
                        })();
                        url += "&searchText=".concat(input, "&searchEnabledFields=").concat(formatSearchLockParameter);
                    }
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch(url, {
                            method: "GET",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                "Cache-Control": "no-cache"
                            },
                            credentials: "include"
                        })];
                case 2:
                    response = _e.sent();
                    if (!response.ok) {
                        throw new Error(REQUEST_ERROR_MESSAGE);
                    }
                    return [4 /*yield*/, response.json()];
                case 3:
                    producerPulseData = _e.sent();
                    return [2 /*return*/, producerPulseData];
                case 4:
                    error_1 = _e.sent();
                    throw new Error(REQUEST_ERROR_MESSAGE);
                case 5: return [2 /*return*/];
            }
        });
    });
}
