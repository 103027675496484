import React, { Component } from "react";
import {
  func,
  shape,
  string,
  objectOf,
  bool,
  number,
  object,
  oneOfType
} from "prop-types";
import { debounce } from "lodash";
import { Icon, Nav, NavDropdown, NavItem, MenuItem } from "@icg360/ui-toolkit";
import { theme } from "@icg360/design-system";
import { withRouter, matchPath } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import {
  MAIN_NAV,
  QANDP_AGENT_PORTAL_NOTICES,
  MAIN_NAV_LINKS_DROPDOWN,
  track
} from "@package/ipcmgr-toolkit";
import styled from "styled-components";
import { withWhatsNew } from "../hooks/useWhatsNew";
import { configPropTypes } from "../config";
import { userPropTypes, hasModuleAccess } from "../utils/user";
import trackedRoutes from "../utils/tracked-events";
import { withNotices } from "../hooks/useNotices";
import "./MainNav.css";

const Notification = styled.div`
  align-items: center;
  background-color: ${theme.colors.text.success};
  border-radius: 50%;
  color: ${theme.colors.text.inverse};
  display: flex;
  font-size: 9px;
  font-weight: bold;
  justify-content: center;
  line-height: 11px;
  height: 14px;
  width: 14px;
`;

const LargeNotification = styled(Notification)`
  font-size: 10px;
  line-height: 16px;
  position: absolute;
  top: 8px;
  right: 22px;
  display: block;
  text-align: center;
`;

const NotificationWrapper = styled.div`
  align-items: center;
  display: flex;

  ${Notification} {
    margin-left: 5px;
  }
`;

const PINNED = 3;
const MIN_SCREEN_WIDTH = 1990;

const isModuleRoute = ({ pathname }, moduleId) =>
  matchPath(pathname, { path: `/${moduleId}` });
class MainNav extends Component {
  static propTypes = {
    config: shape(configPropTypes).isRequired,
    flags: objectOf(oneOfType([bool, object])).isRequired,
    user: shape(userPropTypes).isRequired,
    onLogout: func.isRequired,
    updateAlreadyViewedNoticeIds: func.isRequired,
    newNoticesCount: number,
    updateAlreadyViewedReleaseNotes: func.isRequired,
    newReleaseNoteCount: number,
    location: shape({
      pathname: string
    }).isRequired
  };

  static defaultProps = {
    newNoticesCount: 0,
    newReleaseNoteCount: 0
  };

  static getDerivedStateFromProps({ location }, { activeModuleRoute }) {
    const activeModule = MAIN_NAV.find(({ key }) =>
      isModuleRoute(location, key)
    );
    if (activeModule && activeModuleRoute[activeModule.key] !== location) {
      return {
        activeModuleRoute: {
          ...activeModuleRoute,
          [activeModule.key]: location
        }
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      activeModuleRoute: {},
      menuOpen: false,
      referralsCount: 0,
      screenWidth: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", debounce(this.handleResize, 150));
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
      updateAlreadyViewedNoticeIds,
      updateAlreadyViewedReleaseNotes
    } = this.props;
    const {
      location: { pathname: oldPathname }
    } = prevProps;
    if (pathname !== oldPathname) {
      updateAlreadyViewedReleaseNotes();
      updateAlreadyViewedNoticeIds();
      this.handleRouteTracking(pathname, oldPathname);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", debounce(this.handleResize, 150));
  }

  handleResize = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  menuToggle = open => {
    this.setState({
      menuOpen: open
    });
  };

  logout = () => {
    const { onLogout } = this.props;
    track("Header: Log Out");
    onLogout();
  };

  handleRouteTracking = (pathname, oldPathname) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const route of trackedRoutes) {
      if (
        !pathname.includes(route.route) &&
        oldPathname.includes(route.route)
      ) {
        track(route.event, {
          trigger: "route change"
        });
      }
    }
  };

  render() {
    const {
      config: { customer },
      flags,
      user,
      location: activeLocation,
      newNoticesCount,
      newReleaseNoteCount
    } = this.props;

    const { activeModuleRoute, menuOpen, screenWidth } = this.state;

    const enabledRoutes = hasModuleAccess(user, MAIN_NAV, flags).map(
      ({ label, key }) => {
        const isActive = isModuleRoute(activeLocation, key);
        return {
          label,
          key,
          isActive,
          location:
            !isActive && activeModuleRoute[key]
              ? activeModuleRoute[key]
              : { pathname: `/${key}` }
        };
      }
    );

    const [pinnedRoutes, menuRoutes] =
      enabledRoutes.length > PINNED + 2 && screenWidth < MIN_SCREEN_WIDTH
        ? [enabledRoutes.slice(0, PINNED), enabledRoutes.slice(PINNED)]
        : [enabledRoutes, []];
    const activeMenuRoute = menuRoutes.find(item => item.isActive);

    const agentPortalNoticesFeatureFlag = flags[QANDP_AGENT_PORTAL_NOTICES];
    const showLinksMenu = flags[MAIN_NAV_LINKS_DROPDOWN];

    const newReleaseNoteCountToShow =
      activeLocation.pathname !== "/whats-new" ? newReleaseNoteCount : 0;

    const newNoticesCountToShow =
      activeLocation.pathname !== "/notices" && agentPortalNoticesFeatureFlag
        ? newNoticesCount
        : 0;

    const notificationCount = newReleaseNoteCountToShow + newNoticesCountToShow;

    return (
      <div className="main-nav">
        <Nav>
          {pinnedRoutes.map(({ label, key, location }) => (
            <LinkContainer key={key} to={location}>
              <NavItem
                data-track={`Nav: ${label}`}
                data-bdd={`main-nav-${key}`}
                className="main-nav-link"
              >
                <span>{label}</span>
              </NavItem>
            </LinkContainer>
          ))}

          {menuRoutes.length > 0 && (
            <NavDropdown
              className="main-nav-menu"
              title={<span>{menuRoutes.length} more&hellip;</span>}
              open={menuOpen}
              onToggle={this.menuToggle}
              id="main-nav-menu"
            >
              {menuRoutes.map(({ key, label, location }) => (
                <LinkContainer
                  key={key}
                  to={location}
                  activeClassName={
                    activeMenuRoute ? "main-nav-menu__item--active" : ""
                  }
                >
                  <MenuItem
                    className="main-nav-menu__item"
                    data-track={`Nav: ${label}`}
                    data-bdd={`main-nav-${key}`}
                  >
                    {label}
                  </MenuItem>
                </LinkContainer>
              ))}
            </NavDropdown>
          )}
        </Nav>

        <Nav pullRight>
          <NavDropdown
            data-track="Nav: User Clicks on User Dropdown"
            title={
              <>
                <Icon name="person" />
                {!!notificationCount && (
                  <LargeNotification data-bdd="main-nav-total-notifications">
                    {notificationCount}
                  </LargeNotification>
                )}
              </>
            }
            id="nav-dropdown-user"
          >
            <MenuItem header data-bdd="nav-dropdown-user-username">
              {user.username}
            </MenuItem>
            {agentPortalNoticesFeatureFlag && (
              <LinkContainer to="/notices">
                <MenuItem
                  data-bdd="nav-dropdown-notices"
                  data-track="Nav: User Clicks on Notices"
                >
                  <NotificationWrapper>
                    Notices{" "}
                    {!!newNoticesCountToShow && (
                      <Notification data-bdd="main-nav-notices-notifications">
                        {newNoticesCountToShow}
                      </Notification>
                    )}
                  </NotificationWrapper>
                </MenuItem>
              </LinkContainer>
            )}
            <LinkContainer to="/whats-new">
              <MenuItem
                data-bdd="nav-dropdown-whats-new"
                data-track="Nav: User Clicks on What's New"
              >
                <NotificationWrapper>
                  What&apos;s New{" "}
                  {!!newReleaseNoteCountToShow && (
                    <Notification data-bdd="main-nav-whats-new-notifications">
                      {newReleaseNoteCountToShow}
                    </Notification>
                  )}
                </NotificationWrapper>
              </MenuItem>
            </LinkContainer>
            <LinkContainer to="/reset-password">
              <MenuItem data-track="Nav: User Clicks on Reset Password">
                Reset Password
              </MenuItem>
            </LinkContainer>
            <MenuItem
              onSelect={this.logout}
              data-track="Log Out"
              data-bdd="nav-dropdown-user-logout"
            >
              Log Out
            </MenuItem>
          </NavDropdown>
          {showLinksMenu && (
            <NavDropdown
              title="Links"
              id="nav-dropdown-tools"
              data-track="Nav: User Clicks on Links Dropdown"
            >
              {customer.usefulLinks.map(({ label, href }) => (
                <MenuItem
                  key={label}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-track={`Useful Link: ${label}`}
                  data-bdd={`useful-link-${label.replace(" ", "-")}`}
                >
                  <Icon name="open_in_new" />
                  <span>{label}</span>
                </MenuItem>
              ))}
            </NavDropdown>
          )}
        </Nav>
      </div>
    );
  }
}

export default withRouter(withWhatsNew(withNotices(MainNav)));
