import React from "react";
import { theme } from "@icg360/design-system";

export default function setReadOnlyAccess(readOnly) {
  (async () => {
    if (readOnly) {
      await import("../styles/ReadOnlyAccess.css");
    }
  })();
  return (
    readOnly && (
      <div
        style={{
          background: theme.colors.fill.canvas,
          textAlign: "center",
          fontWeight: "bold"
        }}
      >
        Read only view
      </div>
    )
  );
}
