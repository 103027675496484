/* eslint-disable camelcase */
import moment from "moment";
import { escape, isArray, isObject } from "lodash";
import PolicyParser from "@package/policy-parser";
import postPolicyNote from "qandp/promises/post-policy-note";
import { UW_CONDITION_TEXT } from "qandp/constants";
import { setReasonCode } from "../forms/cancel-reinstate/utils";
import { INSURED_CANCEL_DATA_ITEM_NAMES } from "../constants/cancel-constants";
import checkForVersionError from "./utils";

// Set Underwriting Conditions
const uwConditions = ({ underwritingConditions }) =>
  underwritingConditions
    .map(
      (cond, i) =>
        `<DataItem name="${UW_CONDITION_TEXT}${i + 1}" value="${cond}"/>`
    )
    .join("\n");

// Set Insured Cancel Reason
const icReasons = (values, showLegacyForm, shouldGetUpdatedDataNames) => {
  const { insuredCancelReason } = values;

  if (!shouldGetUpdatedDataNames || showLegacyForm)
    return `<DataItem name="InsuredCancelReasonComment" value="${
      values.insuredCancelReasonComment
    }"/>`;

  return (
    INSURED_CANCEL_DATA_ITEM_NAMES?.[insuredCancelReason]
      ?.map(itemName => {
        const dataItemName = `${itemName[0].toLowerCase()}${itemName.slice(1)}`;
        let dataItemValue = values[dataItemName];

        if (!dataItemValue || dataItemValue?.length <= 0) return null;

        if (isArray(dataItemValue)) {
          return dataItemValue
            .map(
              (value, index) =>
                `<DataItem name="${itemName}${index + 1}" value="${value}"/>`
            )
            .join("\n");
        }

        // Date object
        if (isObject(dataItemValue))
          dataItemValue = moment(dataItemValue).format("YYYY-MM-DD");

        return `<DataItem name="${itemName}" value="${dataItemValue}"/>`;
      })
      ?.filter(value => value)
      ?.join("\n") || ""
  );
};

export default async ({
  id,
  serviceUrl,
  policy,
  commit,
  username,
  values,
  hideInsuredCancelReason,
  hideUnderWritingConditions,
  insuredRequestReasonCodeOverride,
  showUpdatedInsuredCancelReasons,
  isWrittenCancellationSelected,
  sameDayDeliveryEnabled,
  isSameDayDelivery
}) => {
  const { action, comment, note, effectiveDate } = values;
  const {
    isCommercial,
    isGeico: isGeicoPolicy,
    version,
    identifiers: { insightPolicyId }
  } = policy;
  const formattedEffectiveDate = moment(effectiveDate || moment()).format(
    "YYYY-MM-DD"
  );
  const isBop = isCommercial;
  const isGeico = isGeicoPolicy;
  const showLegacyForm = isBop || isGeico;
  const shouldGetUpdatedDataNames =
    showUpdatedInsuredCancelReasons && !showLegacyForm;

  const getReasonCode = setReasonCode({
    action,
    hasFlag: insuredRequestReasonCodeOverride,
    formValues: values,
    policy
  });

  const sameDayDeliveryData = sameDayDeliveryEnabled
    ? `<DocumentDispatchCommands sameDayPrint="${isSameDayDelivery}" />`
    : "";

  const intervalRequestData =
    !hideInsuredCancelReason || !hideUnderWritingConditions
      ? `<IntervalRequest>
        ${
          !hideUnderWritingConditions && values?.underwritingConditions
            ? uwConditions(values)
            : ""
        }
        ${
          !hideInsuredCancelReason
            ? `${
                isWrittenCancellationSelected
                  ? '<DataItem name="InsuredCancelAcordEmailCancellation" value="true"/>'
                  : ""
              }
                <DataItem name="InsuredCancelReason" value="${
                  values.insuredCancelReason
                }"/>
               ${icReasons(values, showLegacyForm, shouldGetUpdatedDataNames)}`
            : ""
        }
        </IntervalRequest>`
      : "";

  const body = `<TransactionRequest schemaVersion="1.4" type="${action.replace(
    "Bop",
    ""
  )}">
  <Initiation>
    <Initiator type="user">${username}</Initiator>
  </Initiation>
  <Target>
    <Identifiers>
      <Identifier name="InsightPolicyId" value="${insightPolicyId}"/>
    </Identifiers>
    <SourceVersion>${version}</SourceVersion>
  </Target>
  <EffectiveDate>${formattedEffectiveDate}</EffectiveDate>
  <ReasonCode>${getReasonCode.toString().replace("_bop", "")}</ReasonCode>
  <Comment>${escape(comment)}</Comment>
  ${intervalRequestData}
  ${sameDayDeliveryData}
</TransactionRequest>`;

  const reqOpts = {
    body,
    method: "POST",
    headers: {
      Accept: "application/vnd.ics360.insurancepolicy.2.8+xml",
      "Content-Type": "application/xml; schema=transactionrequest.1.4",
      "X-Commit": commit,
      "X-IPM-Action": commit ? "CancelReinstate" : "CancelReinstatePreview"
    },
    mode: "cors",
    credentials: "include"
  };

  const req = new Request(`${serviceUrl}/policies/${id}`, reqOpts);
  try {
    const resp = await fetch(req);
    if (!resp.ok) {
      const errorMsg = checkForVersionError(resp);
      return Promise.reject(new Error(errorMsg));
    }
    if (note)
      await postPolicyNote({
        policyId: id,
        serviceUrl,
        username,
        note
      });
    const xmlResp = await resp.text();
    return PolicyParser.parse(xmlResp);
  } catch (e) {
    return Promise.reject(e);
  }
};
