import { isEmpty } from "lodash";

/**
 * Calculates policy state based on several factors
 * @param {object} policy
 * @return {string} The calculated state of the policy.
 */

// eslint-disable-next-line import/prefer-default-export
export const calculatePolicyState = ({
  pendingCancellation,
  pendingNonRenewal,
  policyState,
  rating
}) => {
  const { ratingStatus } = rating || {};

  if (policyState === "ACTIVEPOLICY") {
    if (!isEmpty(pendingCancellation)) {
      return "PENDINGCANCELLATION";
    }
    if (!isEmpty(pendingNonRenewal)) {
      return "PENDINGNONRENEWAL";
    }
  } else if (policyState === "ACTIVEQUOTE" && ratingStatus !== "complete") {
    return "INCOMPLETEQUOTE";
  }
  return policyState;
};
