import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFetch } from "@package/ipcmgr-toolkit";
import moment from "moment";
import Status from "./Status";
import getGitLogs from "../promises/getGitLogs";
import { configPropTypes } from "../config";
import "./Footer.css";

Footer.propTypes = {
  config: PropTypes.shape(configPropTypes).isRequired
};

export default function Footer({ config }) {
  const {
    customer,
    customerId,
    statusApiUrl,
    statusPageUrl,
    environmentId
  } = config;
  const [latestCommit, setLatestCommit] = useState("---");

  useFetch({
    request: getGitLogs,
    using: {},
    willUpdate: state => {
      const { status, data } = state;
      if (status === "DONE" && data.length) setLatestCommit(data.shift()?.date);
    }
  });

  return (
    <footer className="footer">
      <a
        href={customer.url}
        className="footer__logo footer__logo--customer"
        target="_blank"
        rel="noopener noreferrer"
        data-bdd="footer-customer-url"
        data-track="Footer: SageSure"
      >
        <img
          src={`/customers/${customerId}/footer-logo.svg`}
          width="109"
          height="26"
          alt={`${customer.displayName} logo`}
          title={customer.displayName}
          data-bdd="footer-customer-logo"
        />
      </a>
      <ul className="footer__list">
        <li data-bdd="footer-copyright">{`© ${new Date().getFullYear()} ${
          customer.displayName
        }`}</li>
        <li data-bdd="footer-last-updated">
          Last Updated:{" "}
          <code>{moment(latestCommit).format("MMM D, YYYY h:mma")}</code>
        </li>
        <li data-bdd="footer-build-version">
          Build: <code>{__VERSION__}</code>
        </li>
        {customer.footerSupportUrl && (
          <li>
            <a
              href={customer.footerSupportUrl}
              target="_blank"
              rel="noopener noreferrer"
              data-bdd="footer-support-url"
              data-track="Footer: Contact Support"
            >
              Contact Support
            </a>
          </li>
        )}
        {environmentId === "prod" && statusApiUrl && (
          <li data-bdd="footer-app-status">
            <Status api={statusApiUrl} page={statusPageUrl} />
          </li>
        )}
      </ul>
    </footer>
  );
}
