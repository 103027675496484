import React from "react";
import styled from "styled-components";
import { Spinner } from "@icg360/design-system";

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

export default () => (
  <Page data-bdd="loading-page">
    <Spinner />
  </Page>
);
