import {
  PAYMENT_READY,
  SAVE_CC_CANCEL,
  SAVE_CC_COMPLETE,
  SAVE_CC_ERROR,
  SAVE_PAYMENT_CC,
  SAVE_PAYMENT_ECHECK
} from "@icg360/payment-toolkit";
import { withRouter } from "react-router-dom";
import PT from "prop-types";
import { useContext, useEffect } from "react";
import { SAVE_PAYMENT_INFO } from "qandp/constants";
import { QPContext } from "qandp/context";

const SavePaymentInfo = ({ history, refetchPolicy, callbackUrl, location }) => {
  const {
    paymentController,
    policy: { identifiers }
  } = useContext(QPContext);
  const { insightPolicyId, policyId } = identifiers || {};
  const params = new URLSearchParams(location.search);
  const type = params.get(SAVE_PAYMENT_INFO);

  const onCancel = () => history.push(callbackUrl);
  const onComplete = () => {
    refetchPolicy();
    history.push(callbackUrl);
  };
  const onError = () => history.push(callbackUrl);

  useEffect(
    () => {
      if (![SAVE_PAYMENT_CC, SAVE_PAYMENT_ECHECK].includes(type))
        return undefined;
      const save = () => {
        paymentController.savePaymentMethod({
          insightPolicyId,
          policyId,
          type
        });
      };
      paymentController.addEventListener(PAYMENT_READY, save);
      paymentController.addEventListener(SAVE_CC_CANCEL, onCancel);
      paymentController.addEventListener(SAVE_CC_ERROR, onError);
      paymentController.addEventListener(SAVE_CC_COMPLETE, onComplete);
      if (paymentController.isReady()) {
        save();
      }
      return () => {
        paymentController.removeEventListener(PAYMENT_READY, save);
        paymentController.removeEventListener(SAVE_CC_CANCEL, onCancel);
        paymentController.removeEventListener(SAVE_CC_ERROR, onError);
        paymentController.removeEventListener(SAVE_CC_COMPLETE, onComplete);
      };
    },
    [insightPolicyId, policyId, type]
  );

  return null;
};

SavePaymentInfo.propTypes = {
  history: PT.object.isRequired,
  refetchPolicy: PT.func.isRequired,
  callbackUrl: PT.string.isRequired,
  location: PT.object.isRequired
};

export default withRouter(SavePaymentInfo);
