import React from "react";
import PropTypes from "prop-types";
import { Fetch } from "@package/ipcmgr-toolkit";

import { fetchJSON } from "../utils/fetch";
import "./Status.css";

// This seems to be pointless because `getStatus` does the same thing as
// `fetchJSON`, but Fetch will create a new cache with `getStatus` as the key.
const getStatus = ({ api }) => fetchJSON(api);

Footer.propTypes = {
  api: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired
};

export default function Footer({ api, page }) {
  return (
    <Fetch
      request={getStatus}
      using={{ api }}
      cacheType={Fetch.CACHE_TYPES.SINGLE}
      render={({ data }) =>
        data && data.status ? (
          <a
            href={page}
            target="_blank"
            rel="noopener noreferrer"
            className="status"
            data-status={data.status.indicator}
          >
            {data.status.description}
          </a>
        ) : null
      }
    />
  );
}
