import { getPacActions } from "@icg360/pac";
import getAssignees from "./get-assignees";
import getAnalysis from "./get-analysis";
import getAnalysisPackages from "./get-analysis-packages";
import brokerOfRecord from "../components/tabs/PolicyActions/PolicyForm/promises/broker-of-record";
import cancelReinstate from "../components/tabs/PolicyActions/PolicyForm/promises/cancel-reinstate";
import nonRenew from "../components/tabs/PolicyActions/PolicyForm/promises/non-renew";
import getGitLogs from "../../../../src/promises/getGitLogs";
import { getOrg } from "./get-orgs";
import getInspectionDoc from "./get-inspection-doc";
import getPacData from "./get-pac-data";
import getPolicyXml from "./get-policy-xml";
import getQuotesClaim from "./get-quotes-claim";
import getQuotingEligibility from "./get-quoting-eligibility-data";
import getRulesets from "./get-rulesets";
import getVocab from "./get-vocab";
import getHomeServicesOffers from "./get-home-services-offers";

export default {
  getAssignees,
  getAnalysis,
  getAnalysisPackages,
  getGitLogs,
  brokerOfRecord,
  cancelReinstate,
  getOrg,
  getInspectionDoc,
  getPacData,
  getPolicyXml,
  getVocab,
  getQuotesClaim,
  getQuotingEligibility,
  getRulesets,
  getPacActions,
  nonRenew,
  getHomeServicesOffers
};
