import { arrayOf, oneOf, shape, string } from "prop-types";
import {
  setMetaContent,
  injectCSSTop,
  setBrowserTitle,
  addScriptToBottom
} from "./utils/dom";
import { fetchJSON, fetchText } from "./utils/fetch";

/**
 * Config type definitions
 */
const servicePropType = shape({
  url: string.isRequired,
  basePath: string.isRequired
});
export const configPropTypes = {
  agentSupportUrl: string.isRequired,
  environmentId: oneOf(["stage", "prod"]).isRequired,
  customerId: oneOf(["sagesure", "fednat"]).isRequired,
  loginRequiredRole: string,
  defaultModule: arrayOf(string).isRequired,
  googleMapsKey: string,
  launchDarklyKey: string.isRequired,
  microsoftSSOUrl: string,
  mixpanelToken: string,
  qualtricsId: string,
  qualtricsKey: string,
  qualtricsUrl: string,
  statusApiUrl: string,
  statusPageUrl: string,
  zendeskTicketsUrl: string,
  profilesWorkflowsUrl: string,
  awsFnolForms: string,

  // Retrieved async from file, see: `/customers/${CUSTOMER_ID}/customer.json`
  customer: shape({
    displayName: string,
    url: string,
    footerSupportUrl: string,
    usefulLinks: arrayOf(
      shape({
        label: string.isRequired,
        href: string.isRequired
      })
    ).isRequired
  }).isRequired,

  rulesetTags: arrayOf(string).isRequired,

  services: shape({
    url: string,
    password: servicePropType,
    ixlibrary: servicePropType,
    ixprofiler: servicePropType,
    ixvocab: servicePropType,
    pxcentral: servicePropType,
    pxserver: servicePropType,
    mxserver: servicePropType,
    quoting: servicePropType,
    batches: servicePropType,
    ixdirectory: servicePropType,
    esign: servicePropType,
    userservices: servicePropType
  }).isRequired,

  graphql: shape({
    configuration: string,
    renewals: string,
    policy: string,
    policies: string
  }).isRequired,

  apps: shape({
    policycentral: shape({
      url: string,
      basePathPolicy: string
    })
  }),
  commandBarOrgId: string,
  googleApiKey: string
};

/**
 * Config - initially defined here, but then modified by `loadRuntimeConfig()`
 * at runtime right before the app mounts.
 *
 * `undefined` represents those values which get set at runtime
 */
export const createConfig = ({
  runtimeConfig: {
    AGENT_SUPPORT_URL,
    ENVIRONMENT_ID,
    CUSTOMER_ID,
    LOGIN_REQUIRED_ROLE,
    DEFAULT_MODULE,
    GOOGLE_MAPS_KEY,
    LAUNCH_DARKLY_KEY,
    MICROSOFT_SSO_URL,
    MIXPANEL_TOKEN,
    QUALTRICS_ID,
    QUALTRICS_KEY,
    QUALTRICS_URL,
    STATUS_API_URL,
    STATUS_PAGE_URL,
    GRAPHQL_URL_CONFIGURATION,
    GRAPHQL_URL_RENEWALS,
    GRAPHQL_URL_POLICY,
    GRAPHQL_URL_POLICIES,
    GRAPHQL_URL_PRODUCTS,
    RULESET_TAGS,
    SERVICE_URL,
    SERVICE_URL_IXVOCAB,
    SERVICE_URL_IXLIBRARY,
    SERVICE_URL_IXPROFILER,
    SERVICE_URL_IXLOGIC,
    SERVICE_URL_IXRELAY,
    SERVICE_URL_RULESETS,
    SERVICE_URL_PAYMENTS,
    SERVICE_URL_PXCENTRAL,
    SERVICE_URL_PXSERVER,
    SERVICE_URL_MXSERVER,
    SERVICE_URL_BATCHES,
    SERVICE_URL_QUOTING,
    SERVICE_URL_IXDIRECTORY,
    SERVICE_URL_ESIGN,
    SERVICE_URL_PROFILES_WORKFLOWS,
    ZENDESK_TICKETS_URL,
    SERVICE_URL_ZENDESK,
    SERVICE_URL_USER_SERVICES,
    AWS_FNOL_FORMS,
    COMMAND_BAR_ORG_ID,
    GOOGLE_API_KEY,
    GOOGLE_API_KEY_PROD
  },
  customerConfig
}) => ({
  agentSupportUrl: AGENT_SUPPORT_URL,
  environmentId: ENVIRONMENT_ID,
  customerId: CUSTOMER_ID,
  loginRequiredRole: LOGIN_REQUIRED_ROLE,
  defaultModule: DEFAULT_MODULE,
  googleMapsKey: GOOGLE_MAPS_KEY,
  launchDarklyKey: LAUNCH_DARKLY_KEY,
  microsoftSSOUrl: MICROSOFT_SSO_URL,
  mixpanelToken: MIXPANEL_TOKEN,
  qualtricsId: QUALTRICS_ID,
  qualtricsKey: QUALTRICS_KEY,
  qualtricsUrl: QUALTRICS_URL,
  statusApiUrl: STATUS_API_URL,
  statusPageUrl: STATUS_PAGE_URL,
  zendeskTicketsUrl: ZENDESK_TICKETS_URL,
  profilesWorkflowsUrl: SERVICE_URL_PROFILES_WORKFLOWS,
  awsFnolForms: AWS_FNOL_FORMS,
  customer: customerConfig,
  rulesetTags: RULESET_TAGS,
  services: {
    url: SERVICE_URL,
    ixlibrary: { url: SERVICE_URL_IXLIBRARY, basePath: "api/sdo/rest/v1" },
    ixprofiler: {
      url: SERVICE_URL_IXPROFILER,
      basePath: "api/rest/v2"
    },
    ixlogic: { url: SERVICE_URL_IXLOGIC, basePath: "" },
    rulesets: { url: SERVICE_URL_RULESETS, basePath: "" },
    ixvocab: {
      url: SERVICE_URL_IXVOCAB,
      basePath: "api/rest/v2",
      v1BasePath: "api/rest/v1"
    },
    ixrelay: { url: SERVICE_URL_IXRELAY, basePath: "api/rest/v1" },
    payments: { url: SERVICE_URL_PAYMENTS, basePath: "" },
    pxcentral: {
      url: SERVICE_URL_PXCENTRAL,
      basePath: "api/rest/v1"
    },
    pxserver: { url: SERVICE_URL_PXSERVER, basePath: "" },
    cxserver: { url: "", basePath: "cxserver/api/rest/v3" },
    mxserver: { url: SERVICE_URL_MXSERVER, basePath: "api/rest/v1" },
    quoting: { url: SERVICE_URL_QUOTING, basePath: "api/rest/v1" },
    batches: { url: SERVICE_URL_BATCHES, basePath: "api/rest/v1/batch" },
    ixdirectory: { url: SERVICE_URL_IXDIRECTORY, basePath: "api/rest/v2" },
    esign: { url: SERVICE_URL_ESIGN, basePath: "api/v3" },
    zendesk: { url: SERVICE_URL_ZENDESK, basePath: "" },
    userservices: { url: SERVICE_URL_USER_SERVICES, basePath: "api/rest/v1" },
    documents: { url: SERVICE_URL_BATCHES, basePath: "api/rest/v1/policies" },
    policies: { url: SERVICE_URL_BATCHES, basePath: "api/rest/v1" },
    analysis: {
      url: SERVICE_URL_BATCHES,
      basePath: "api/rest/v1/iac"
    },
    producerengagement: {
      url: SERVICE_URL_BATCHES,
      basePath: "proxy/producer-pulse/api/rest/v1"
    }
  },
  graphql: {
    configuration: GRAPHQL_URL_CONFIGURATION,
    renewals: GRAPHQL_URL_RENEWALS,
    policy: GRAPHQL_URL_POLICY,
    policies: GRAPHQL_URL_POLICIES,
    products: GRAPHQL_URL_PRODUCTS
  },
  apps: {
    policycentral: { basePathPolicy: "policy" }
  },
  commandBarOrgId: COMMAND_BAR_ORG_ID,
  googleApiKey: ENVIRONMENT_ID === "prod" ? GOOGLE_API_KEY_PROD : GOOGLE_API_KEY
});

export function loadRuntimeConfig() {
  // We append `v=X.X.X` to allow version cache busting on these static assets
  return fetchJSON(`/runtime-config.json?v=${__VERSION__}`)
    .then(runtimeConfig => {
      // Possibly override the customer if it's in local storage
      // (dev/QA purposes only)
      const customerIdOverride = window.localStorage.getItem("CUSTOMER_ID");
      return customerIdOverride
        ? {
            ...runtimeConfig,
            CUSTOMER_ID: customerIdOverride
          }
        : runtimeConfig;
    })
    .then(runtimeConfig => {
      const { CUSTOMER_ID } = runtimeConfig;
      // DOM manipulation
      setBrowserTitle(runtimeConfig.BROWSER_TITLE);
      // Append CSS at the beginning of <head> (as there could be other <style>
      // elements in <head> and we want this to be the "base" CSS)
      fetchText(`/customers/${CUSTOMER_ID}/styles.css?v=${__VERSION__}`).then(
        injectCSSTop
      );
      // Set <meta> content
      setMetaContent("og:url", window.location.href);
      setMetaContent("og:image", `${window.location.origin}/preview.jpg`);
      // Create <script> for Status.io page
      if (runtimeConfig.ENVIRONMENT_ID === "prod") {
        addScriptToBottom("https://3qg7hpmtz0bn.statuspage.io/embed/script.js");
      }

      return Promise.all([
        runtimeConfig,
        // Fetch customer-related assets (stylesheet, customer config)
        fetchJSON(`/customers/${CUSTOMER_ID}/customer.json?v=${__VERSION__}`)
      ]);
    })
    .then(([runtimeConfig, customerConfig]) =>
      createConfig({ runtimeConfig, customerConfig })
    );
}
