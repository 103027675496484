/* eslint-disable no-console */
import React, { useEffect, Suspense } from "react";
import { shape, objectOf, bool, oneOfType, object } from "prop-types";
import { Route, Switch } from "react-router-dom";
import {
  LoadingPage,
  QANDP_AGENT_PORTAL_NOTICES,
  READ_ONLY_ACCESS
} from "@package/ipcmgr-toolkit";

import { configPropTypes } from "../config";
import setReadOnlyAccess from "../utils/read-only-access";
import { userPropTypes, hasModuleAccess } from "../utils/user";
import { setMetaContent, setBrowserTitle } from "../utils/dom";
import routes from "../routes";
import ModuleBoundary from "./ModuleBoundary";
import UnknownRoute from "./UnknownRoute";
import ResetPassword from "./ResetPassword";
import WhatsNew from "./WhatsNew";
import Notices from "./Notices";

const propTypes = {
  config: shape(configPropTypes).isRequired,
  flags: objectOf(oneOfType([bool, object])).isRequired,
  user: shape(userPropTypes).isRequired
};

const BrowserTitleWrapper = ({ label, config, children }) => {
  const {
    ipcm: { applicationLabel }
  } = config;
  const browserTitle =
    label === applicationLabel ? label : `${label} | ${applicationLabel}`;

  useEffect(
    () => {
      setBrowserTitle(browserTitle);
      setMetaContent("og:title", browserTitle);
      setMetaContent("og:url", window.location.href);
    },
    [label]
  );

  return children;
};

const Main = ({ config, flags, user }) =>
  flags.isReady ? (
    <Suspense
      fallback={
        <main>
          <LoadingPage />
        </main>
      }
    >
      {setReadOnlyAccess(!!flags[READ_ONLY_ACCESS])}
      <Switch>
        {hasModuleAccess(user, routes, flags).map(
          ({ path, component: Module, abbr = "default", label }) => (
            <Route
              path={path}
              exact={path === "/"}
              key={path}
              render={({ location }) => (
                <BrowserTitleWrapper label={label} config={config}>
                  <main className={`clearfix mod-${abbr}`}>
                    <ModuleBoundary
                      label={label}
                      location={location}
                      config={config}
                    >
                      <Module
                        basename={path}
                        config={config}
                        flags={flags}
                        user={user}
                      />
                    </ModuleBoundary>
                  </main>
                </BrowserTitleWrapper>
              )}
            />
          )
        )}
        <Route
          exact
          path="/reset-password"
          render={({ history }) => (
            <BrowserTitleWrapper label="Reset Password" config={config}>
              <main className="clearfix">
                <ResetPassword config={config} user={user} history={history} />
              </main>
            </BrowserTitleWrapper>
          )}
        />
        <Route
          path="/whats-new"
          render={() => (
            <BrowserTitleWrapper label="What's New" config={config}>
              <main className="clearfix">
                <WhatsNew config={config} user={user} flags={flags} />
              </main>
            </BrowserTitleWrapper>
          )}
        />
        {flags[QANDP_AGENT_PORTAL_NOTICES] && (
          <Route
            path="/notices"
            render={() => (
              <BrowserTitleWrapper label="Notices" config={config}>
                <main className="clearfix">
                  <Notices config={config} user={user} flags={flags} />
                </main>
              </BrowserTitleWrapper>
            )}
          />
        )}
        <Route
          render={() => (
            <main className="clearfix">
              <UnknownRoute />
            </main>
          )}
        />
      </Switch>
    </Suspense>
  ) : (
    <main>
      <LoadingPage />
    </main>
  );

Main.propTypes = propTypes;

export default Main;
