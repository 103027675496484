export const DISBURSEMENTS = {
  key: "disbursements",
  abbr: "dis",
  label: "Disbursements",
  flag: "module-disbursements"
};

export const IPC_CONFIGURATION = {
  key: "ipc-configuration",
  abbr: "ipcc",
  label: "Configurations",
  flag: "module-ipc-configuration"
};

export const QUOTES_AND_POLICIES = {
  key: "quotes-and-policies",
  abbr: "qandp",
  label: "Quotes & Policies",
  flag: "module-qandp"
};

export const REFERRALS = {
  key: "referrals",
  abbr: "ref",
  label: "Referrals",
  flag: "module-referrals",
  showNotificationCount: true
};

export const RENEWALS = {
  key: "renewals",
  abbr: "rmm",
  label: "Renewals",
  flag: "module-renewals"
};

export const RENEWAL_UNDERWRITING = {
  key: "renewal-underwriting",
  abbr: "rr",
  label: "Renewal Underwriting",
  flag: "module-renewal-underwriting"
};

export const TRANSACTION_BATCHES = {
  key: "transaction-batches",
  abbr: "tb",
  label: "Transaction Batches",
  flag: "module-transaction-batches"
};

export const AUTH_MANAGER = {
  key: "auth-manager",
  abbr: "am",
  label: "Auth Manager",
  flag: "module-auth-manager",
  roles: ["AUTH_MANAGER", "AUTH_ROLE_ADMIN"]
};

export const PRODUCTS = {
  key: "products",
  abbr: "pro",
  label: "Products",
  flag: "module-products"
};

export const DOCUMENT_TEMPLATES = {
  key: "document-templates",
  abbr: "dm",
  label: "Document Templates",
  flag: "module-document-templates"
};

const PRODUCER_ENGAGEMENT = {
  key: "producer-engagement",
  abbr: "pe",
  label: "Producer Engagement",
  flag: "module-producer-engagement"
};

export const MODULES = [
  DISBURSEMENTS,
  IPC_CONFIGURATION,
  QUOTES_AND_POLICIES,
  REFERRALS,
  RENEWALS,
  RENEWAL_UNDERWRITING,
  TRANSACTION_BATCHES,
  AUTH_MANAGER,
  PRODUCTS,
  DOCUMENT_TEMPLATES,
  PRODUCER_ENGAGEMENT
];

export const MAIN_NAV = [
  QUOTES_AND_POLICIES,
  REFERRALS,
  TRANSACTION_BATCHES,
  DISBURSEMENTS,
  RENEWALS,
  RENEWAL_UNDERWRITING,
  IPC_CONFIGURATION,
  AUTH_MANAGER,
  PRODUCTS,
  DOCUMENT_TEMPLATES,
  PRODUCER_ENGAGEMENT
];
