export const INSURED_REQUEST_REASON_CODE_ENUMS = {
  BETTER_PRICE: "100",
  BETTER_COVERAGE: "110",
  OTHER_REASONS: "120",
  UNSATISFACTORY_CLAIMS_EXPERIENCE: "120",
  UNSATISFACTORY_SERVICE_EXPERIENCE: "130",
  UNSATISFACTORY_UNDERWRITING_EXPERIENCE: "140",
  OTHER_FACTOR: "150",
  NEW_HOME_IN_STATE: "100",
  NEW_HOME_OUT_OF_STATE: "110",
  NO_LONGER_HOME_OWNER: "120",
  CURRENT_MAILING_ADDRESS_VALID: "100",
  CURRENT_MAILING_ADDRESS_INVALID: "200",
  OTHER_DECISION_TO_LEAVE: "130",
  NO_LONGER_AFFORD_PREMIUMS: "100",
  INSURANCE_NOT_WORTH_VALUE: "110",
  OTHER_SELF_INSURE_REASON: "120"
};

export default {
  INSURED_REQUEST: "1",
  NONPAYMENT_OF_PREMIUM: "2",
  INSURED_CONVICTED: "3",
  DISCOVERY_OF_FRAUD: "4",
  DISCOVERY_OF_WILLFUL: "5",
  PHYSICAL_CHANGES: "6",
  INCREASE_IN_LIABILITY: "7",
  INCREASE_IN_PROPERTY: "8",
  OVEREXPOSED: "9",
  CHANGE_IN_OCCUPANCY: "10",
  OTHER_UNDERWRITING_REASONS: "11",
  CANCEL_REWRITE: "12",
  CHANGE_IN_OWNERSHIP: "13",
  MISSING_REQUIRED_DOCS: "14",
  INSURED_REQUEST_SHORT_RATE: "15",
  NONPAYMENT_OF_PREMIUM_FLAT: "16",
  REPLACEMENT_POLICY: "17",
  DUPLICATE_POLICY: "24",
  PAYMENT_RECEIVED_AFTER_EFFECTIVE: "27",
  DUE_TO_COMPLIANCE: "28",
  UNDERWRITING_REASON: "29",
  PROPERTY_SOLD: "100",
  BETTER_PRICE: "110",
  UNSATISFACTORY_EXPERIENCE: "120",
  REWRITE_WITH_SAGESURE: "130",
  OTHER: "140",
  REWRITE_WITH_NON_SAGESURE: "150",
  NO_LONGER_CUSTOMER: "160",
  SELF_INSURED: "170",
  PAYMENT_NOT_RECEIVED: "160",
  PAYMENT_RECEIEVED: "201",
  LOSS_HISTORY_RESOLVED: "205",
  NEGATIVE_FEEDBACK: "206",
  HAZARDS_FOUND: "207",
  REINSTATEMENT_FLAT_CANCEL: "208",
  RENEWAL_NOT_ACCEPTED: "300",
  RENEWAL_PAYMENT_RECEIVED: "209",
  BOOK_ROLL: "304",
  UNACCEPTABLE_WILDFIRE_ZONE: "305",
  INSUFFICIENT_RATE_EXPOSURE: "307",
  NO_LONGER_WRITING_IN_STATE: "309",
  // BOP-specific
  NONPAYMENT_OF_PREMIUM_FINANCE_CO: "19_bop",
  RISK_INELIGIBLE: "21_bop",
  SUBSTANTIAL_RISK_CHANGE: "6_bop",
  LOSS_OF_REINSURANCE: "9_bop",
  MATERIAL_MISREPRESENTATION: "4_bop",
  SUBSTANTIAL_CONTRACT_BREACH: "20_bop",
  LOSS_CONTROL_INSPECTION: "22_bop",
  FRAUDULENT_ACT: "23_bop",
  RISK_UNACCEPTABLE_WILDFIRE_ZONE: "305_bop"
};
