import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { theme } from "@icg360/design-system";
import styled from "styled-components";
import { printDate } from "@package/ipcmgr-toolkit";
import {
  ODEN_STATUSES,
  ODEN_REFERENCE_ID,
  NON_RENEW_INDICATOR_STATUSES,
  DO_NOT_REINSTATE_FLAG_STATUSES,
  HOLD_RENEWAL_FLAG_STATUSES,
  POLICY_STATES,
  MORATORIUMS
} from "qandp/constants";
import { formatMoneyValue } from "qandp/utils";
import { calculatePolicyState } from "qandp/utils/calculatePolicyState";
import { REASON_CODES } from "qandp/components/tabs/PolicyActions/PolicyForm/constants/cancel-constants";
import { PaymentButton } from "qandp/components/shared/OneInc";

const StyledLink = styled(Link)`
  color: ${theme.colors.fill.interactive};
  font-weight: normal;
`;

const StyledPaymentButton = styled(PaymentButton)`
  text-decoration: none;
  &:hover {
    background: none !important;
  }
`;

const getReference = (references = [], identifier) =>
  references.find(({ id }) => id === identifier);

export const getOdenReference = ({
  otherReferences,
  identifiers: { quoteNumber }
}) => getReference(otherReferences, `${ODEN_REFERENCE_ID}-${quoteNumber}`);

export const getNonRenewObj = ({ management }) =>
  management[NON_RENEW_INDICATOR_STATUSES.DEFAULT];

export const getDoNotReinstateObj = ({ management }) =>
  management[DO_NOT_REINSTATE_FLAG_STATUSES.DEFAULT];

export const getHoldRenewalObj = ({ management }) =>
  management[HOLD_RENEWAL_FLAG_STATUSES.DEFAULT];

export const hasMoratoriums = ({ moratoriums }) => {
  const { lookupSuccess, moratoriumReply } = moratoriums || {};
  return !!(lookupSuccess && moratoriumReply?.isUnderMoratorium);
};

export const hasChangeEffectiveDate = ({ eventHistory }) =>
  eventHistory.filter(event => event.type === "ChangeEffectiveDate").pop();

export const getPendingCancellationObj = ({ eventHistory }) =>
  eventHistory.filter(event => event.type === "PendingCancellation").pop();

export const getPendingNonRenewalObj = ({ eventHistory }) =>
  eventHistory.filter(event => event.type === "PendingNonRenewal").pop();

export const allIndicators = policy => {
  const changeEffectiveDate = hasChangeEffectiveDate(policy);
  const doNotReinstate = getDoNotReinstateObj(policy);
  const doNotRenew = getNonRenewObj(policy);
  const holdRenewal = getHoldRenewalObj(policy);
  const pendingCancellation = getPendingCancellationObj(policy);
  const pendingNonRenewal = getPendingNonRenewalObj(policy);
  const odenReference = getOdenReference(policy);
  const {
    accounting: { invoiceDueDateCurrent, minimumPaymentDue, pastDueBalance },
    identifiers: { policyNumber, quoteNumber, policyId },
    policyData: { EffectiveDate, EffectiveDatePrevious }
  } = policy;
  const isPolicy = !!policyId;

  return [
    {
      link: <StyledLink to={`?${MORATORIUMS}=show`}>Details</StyledLink>,
      actionRequired: false,
      label: "SageSure Moratorium / State Emergency Order",
      description: `This ${
        isPolicy ? "policy" : "quote"
      } is in an area impacted by emergency orders and/or moratoriums`,
      hideIndicator: !hasMoratoriums(policy)
    },
    {
      link: (
        <StyledLink
          to={`${quoteNumber || policyNumber}/actions/update-do-not-reinstate`}
        >
          Update Flag
        </StyledLink>
      ),
      actionRequired: false,
      label: "Do Not Reinstate",
      description: `Policy flagged Do-Not-Reinstate on ${printDate(
        doNotReinstate?.lastUpdated
      )}`,
      hideIndicator: !doNotReinstate
    },
    {
      link: (
        <StyledLink
          to={`${quoteNumber || policyNumber}/actions/update-non-renew`}
        >
          Update Flag
        </StyledLink>
      ),
      actionRequired: false,
      label: "Do Not Renew",
      description: `Policy flagged Do-Not-Renew on ${printDate(
        doNotRenew?.lastUpdated
      )}. Process Date ${printDate(doNotRenew?.processDate)}`,
      hideIndicator: !doNotRenew
    },
    {
      link: (
        <StyledLink
          to={`${quoteNumber || policyNumber}/actions/update-hold-renewal`}
        >
          Update Flag
        </StyledLink>
      ),
      actionRequired: false,
      label: "Hold Renewal",
      description: `Policy flagged Hold-Renewal on ${printDate(
        holdRenewal?.lastUpdated
      )}`,
      hideIndicator: !holdRenewal
    },
    {
      actionRequired: true,
      link: (
        <StyledPaymentButton
          appearance="link"
          className="payment-button read-only-access"
        />
      ),
      label: "Past Due Payment",
      description: `Payment for this policy is past due. Payment amount ${formatMoneyValue(
        pastDueBalance
      )}. Payment date: ${printDate(invoiceDueDateCurrent)}`,
      hideIndicator: !(parseFloat(pastDueBalance) !== 0)
    },
    {
      actionRequired: false,
      label: "Policy Pending Cancellation",
      description: `Policy is pending-cancellation for ${
        pendingCancellation?.reasonCodeLabel
      }. Cancellation Effective Date: ${printDate(
        pendingCancellation?.effectiveDate
      )}`,
      hideIndicator: !(
        calculatePolicyState(policy) === POLICY_STATES.PENDINGCANCELLATION.state
      )
    },
    {
      actionRequired: false,
      label: "Policy Pending Cancellation (ODEN)",
      description: `Policy is pending-cancellation for ${
        REASON_CODES[(odenReference?.reasonCode)]
      }. Cancellation Effective Date: ${printDate(
        odenReference?.effectiveDate
      )}`,
      hideIndicator: !(
        (odenReference || {}).state === ODEN_STATUSES.ODEN_PENDING_CANCELLATION
      )
    },
    {
      actionRequired: false,
      label: "Policy Pending Non-Renewal",
      description: `Policy is pending non-renewal for ${
        pendingNonRenewal?.reasonCodeLabel
      }`,
      hideIndicator: !(
        calculatePolicyState(policy) === POLICY_STATES.PENDINGNONRENEWAL.state
      )
    },
    {
      actionRequired: false,
      link: (
        <StyledPaymentButton
          appearance="link"
          className="payment-button read-only-access"
        />
      ),
      label: "Payment Due",
      description: `This policy has a payment due. Due date: ${printDate(
        invoiceDueDateCurrent
      )}`,
      hideIndicator: !(
        parseFloat(minimumPaymentDue) > 0 &&
        moment(new Date()).isSameOrBefore(
          moment(new Date(invoiceDueDateCurrent))
        )
      )
    },
    {
      actionRequired: false,
      label: "Change of effective date",
      description: `The effective date of this policy has been changed from ${printDate(
        EffectiveDatePrevious
      )} to ${printDate(EffectiveDate)}`,
      hideIndicator: !(
        changeEffectiveDate &&
        moment(new Date()) <= moment(new Date(EffectiveDate)).add(10, "days")
      )
    }
  ];
};
