export default [
    "AEGIS SECURITY INSURANCE COMPANY",
    "AFFILIATED F M INSURANCE COMPANY",
    "AIG PROPERTY CASUALTY COMPANY",
    "ALLIED PROPERTY AND CASUALTY INSURANCE COMPANY",
    "ALLSTATE INDEMNITY COMPANY",
    "ALLSTATE INSURANCE COMPANY",
    "AMCO INSURANCE COMPANY",
    "AMERICAN BANKERS INSURANCE COMPANY OF FLORIDA",
    "AMERICAN MODERN HOME INSURANCE COMPANY",
    "AMERICAN MODERN PROPERTY AND CASUALTY INSURANCE COMPANY",
    "AMERICAN NATIONAL PROPERTY AND CASUALTY COMPANY",
    "AMERICAN RELIABLE INSURANCE COMPANY",
    "AMERICAN SECURITY INSURANCE COMPANY",
    "AMICA MUTUAL INSURANCE COMPANY",
    "ARMED FORCES INSURANCE EXCHANGE",
    "ASI SELECT INSURANCE CORP",
    "ASPEN AMERICAN INSURANCE COMPANY",
    "BANKERS STANDARD INSURANCE COMPANY",
    "CALIFORNIA AUTOMOBILE INSURANCE COMPANY",
    "CALIFORNIA CAPITAL INSURANCE COMPANY",
    "CALIFORNIA CASUALTY INDEMNITY EXCHANGE",
    "CALIFORNIA MUTUAL INSURANCE COMPANY",
    "CATLIN INSURANCE COMPANY INC",
    "CENTURY-NATIONAL INSURANCE COMPANY",
    "CINCINNATI INSURANCE COMPANY",
    "CIVIC PROPERTY AND CASUALTY COMPANY",
    "CIVIL SERVICE EMPLOYEES INSURANCE COMPANY",
    "CLEAR BLUE INSURANCE COMPANY",
    "CONSTITUTION INSURANCE COMPANY",
    "CRESTBROOK INSURANCE COMPANY",
    "CSAA INSURANCE EXCHANGE",
    "CSE SAFEGUARD INSURANCE COMPANY",
    "EAGLE WEST INSURANCE COMPANY",
    "ELECTRIC INSURANCE COMPANY",
    "ENCOMPASS INSURANCE COMPANY",
    "EXACT PROPERTY AND CASUALTY COMPANY",
    "FARMERS INSURANCE EXCHANGE",
    "FEDERAL INSURANCE COMPANY",
    "FIDELITY AND DEPOSIT COMPANY OF MARYLAND",
    "FIRE INSURANCE EXCHANGE",
    "FIRST AMERICAN PROPERTY AND CASUALTY INSURANCE COMPANY",
    "FIRST AMERICAN SPECIALTY INSURANCE COMPANY",
    "FIRST NATIONAL INSURANCE COMPANY OF AMERICA",
    "FOREMOST INSURANCE COMPANY GRAND RAPIDS MICHIGAN",
    "FOREMOST PROPERTY AND CASUALTY INSURANCE COMPANY",
    "GARRISON PROPERTY AND CASUALTY INSURANCE COMPANY",
    "GENERAL INSURANCE COMPANY OF AMERICA",
    "GOLDEN BEAR INSURANCE COMPANY",
    "GRANGE INSURANCE ASSOCIATION",
    "HOMESITE INSURANCE COMPANY OF CALIFORNIA",
    "HOMESITE INSURANCE COMPANY OF THE MIDWEST",
    "HORACE MANN INSURANCE COMPANY",
    "HORACE MANN PROPERTY AND CASUALTY INSURANCE COMPANY",
    "HYUNDAI MARINE AND FIRE INSURANCE CO LTD",
    "IDS PROPERTY CASUALTY INSURANCE COMPANY",
    "INTEGON NATIONAL INSURANCE COMPANY",
    "INTERINSURANCE EXCHANGE OF THE AUTOMOBILE CLUB",
    "KEMPER INDEPENDENCE INSURANCE COMPANY",
    "LEMONADE INSURANCE COMPANY",
    "LIBERTY INSURANCE CORPORATION",
    "MAPFRE INSURANCE COMPANY",
    "MARKEL AMERICAN INSURANCE COMPANY",
    "MERASTAR INSURANCE COMPANY",
    "METROPOLITAN DIRECT PROPERTY AND CASUALTY INSURANCE COMPANY",
    "MID-CENTURY INSURANCE COMPANY",
    "NATIONWIDE INSURANCE COMPANY OF AMERICA",
    "NATIONWIDE MUTUAL FIRE INSURANCE COMPANY",
    "NEIGHBORHOOD SPIRIT PROPERTY AND CASUALTY COMPANY",
    "OCCIDENTAL FIRE AND CASUALTY COMPANY OF NORTH CAROLINA",
    "OREGON MUTUAL INSURANCE COMPANY",
    "PACIFIC INDEMNITY COMPANY",
    "PACIFIC PROPERTY AND CASUALTY COMPANY",
    "PACIFIC SPECIALTY INSURANCE COMPANY",
    "PRAETORIAN INSURANCE COMPANY",
    "PRIVILEGE UNDERWRITERS RECIPROCAL EXCHANGE",
    "PROPERTY AND CASUALTY INSURANCE COMPANY OF HARTFORD",
    "QBE INSURANCE CORPORATION",
    "RESIDENCE MUTUAL INSURANCE COMPANY",
    "SAFECO INSURANCE COMPANY OF AMERICA",
    "SAFECO INSURANCE COMPANY OF ILLINOIS",
    "SENTINEL INSURANCE COMPANY LTD",
    "SPINNAKER INSURANCE COMPANY",
    "STANDARD GUARANTY INSURANCE COMPANY",
    "STATE FARM GENERAL INSURANCE COMPANY",
    "STATE NATIONAL INSURANCE COMPANY INC",
    "STILLWATER INSURANCE COMPANY",
    "STILLWATER PROPERTY AND CASUALTY INSURANCE COMPANY",
    "SUTTER INSURANCE COMPANY",
    "TOKIO MARINE AMERICA INSURANCE COMPANY",
    "TOPA INSURANCE COMPANY",
    "TRAVELERS COMMERCIAL INSURANCE COMPANY",
    "TRUMBULL INSURANCE COMPANY",
    "TWIN CITY FIRE INSURANCE COMPANY",
    "UNIGARD INSURANCE COMPANY",
    "UNITED SERVICES AUTOMOBILE ASSOCIATION",
    "UNITRIN DIRECT PROPERTY AND CASUALTY COMPANY",
    "UNIVERSAL NORTH AMERICA INSURANCE COMPANY",
    "USAA CASUALTY INSURANCE COMPANY",
    "USAA GENERAL INDEMNITY COMPANY",
    "VIGILANT INSURANCE COMPANY",
    "WAWANESA GENERAL INSURANCE COMPANY",
    "WESTERN MUTUAL INSURANCE COMPANY",
    "ZURICH AMERICAN INSURANCE COMPANY"
];
