// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../public/customers/sagesure/img/microsoft-logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  width: 100%;\n}\n\n.login::before {\n  content: \"\";\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  background: linear-gradient(\n      to bottom,\n      rgba(255, 255, 255, 0.9) 0%,\n      rgba(255, 255, 255, 0.9) 100%\n    ),\n    url(https://static.pexels.com/photos/731082/pexels-photo-731082.jpeg)\n      no-repeat center/cover;\n}\n\n.login__wrap {\n  position: relative;\n  display: flex;\n  flex: 0 0 auto;\n  align-items: center;\n  width: -moz-fit-content;\n  width: fit-content;\n  max-width: 1360px;\n  height: 100%;\n  background: var(--fill-surface);\n  padding: 0 8%;\n}\n\n.login__main {\n  margin: auto;\n}\n\n.login__logo {\n  padding: 40px;\n}\n\n.login__main .login__form input {\n  margin-bottom: 25px;\n}\n\n.login__main .alert {\n  margin-top: 15px;\n}\n\n.login__main .login-button-sso {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")\n    no-repeat 13px;\n  background-size: 20px;\n  margin-bottom: 50px;\n}\n\n.login__main .enforce-sso-wrapper {\n  margin-bottom: 35px;\n}\n\n.forgot-password {\n  display: block;\n  text-align: center;\n  padding-top: 25px;\n}\n\n.separator {\n  display: flex;\n  align-items: center;\n  text-align: center;\n  margin: 25px 0;\n  font-weight: 500;\n  color: var(--text-neutral-dark);\n}\n\n.separator::before,\n.separator::after {\n  content: \"\";\n  flex: 1;\n  border-bottom: 1px solid var(--border-default);\n}\n\n.separator:not(:empty)::before {\n  margin-right: 1.5em;\n}\n\n.separator:not(:empty)::after {\n  margin-left: 1.5em;\n}\n", ""]);
// Exports
module.exports = exports;
