const analysisFields = [
  { name: "DiffTIV", label: "Total Insured Value" },
  { name: "DiffAAL", label: "Marginal AAL" },
  { name: "DiffPremium", label: "Total Premium" },
  { name: "DiffNonCatLosses", label: "Expected NonCat Losses" },
  { name: "DiffExpenses", label: "Expenses" },
  { name: "DiffReinsuranceCost", label: "Marginal Reinsurance Cost" },
  { name: "DiffRetainedLosses", label: "Marginal Retained Losses" },
  { name: "DiffProfit", label: "Marginal Profit" },
  { name: "DiffCapitalRequired", label: "Marginal Capital Required" },
  { name: "DiffRiskReturnOnCapital", label: "Marginal Return on Captial" },
  { name: "CalcAALDivPremium", label: "AAL/Premium" },
  { name: "CalcPMLDivPremium", label: "PML/Premium" }
];

export default ({ analysisLocation, analysisApiUrl }) =>
  fetch(`${analysisApiUrl}${analysisLocation.split(/(\/risks.*)/)[1]}`, {
    method: "GET",
    credentials: "include"
  })
    .then(res => {
      if (!res.ok) throw Error("Unable to retrieve analysis.");
      return res.text();
    })
    .then(xmlStr =>
      new window.DOMParser().parseFromString(xmlStr, "application/xml")
    )
    .then(xml =>
      analysisFields.map(field => ({
        ...field,
        value: xml.evaluate(
          `//RiskAnalysis/Results/Scoring/DataItem[@name="${
            field.name
          }"]/@value`,
          xml,
          null,
          XPathResult.STRING_TYPE,
          null
        ).stringValue
      }))
    );
