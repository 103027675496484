import { escape } from "lodash";

const createTaskDataItem = taskProps =>
  taskProps.id
    ? `\n\t\t<DataItem name="SendNotification" value="Message Added"/>`
    : "";

const createNoteXml = (taskProps, note) =>
  note
    ? `\n\t<Note>${createTaskDataItem(
        taskProps
      )}\n\t\t<Content><![CDATA[${escape(note)}]]></Content>\n\t</Note>`
    : "";

const createAttachmentXml = attachments =>
  attachments
    ? `\n\t<Attachments>
     ${attachments
       .map(
         attachment =>
           `\n\t\t<Attachment name="${attachment.name}" contentType="${
             attachment.type
           }">\n\t\t\t<Description/>\n\t\t\t<Location>${
             attachment.location
           }</Location>\n\t\t</Attachment>`
       )
       .join("")}
       \n\t</Attachments>`
    : "";

const createTask = taskProps =>
  taskProps.id
    ? `<Task id="${taskProps.id}" status="${
        taskProps.status
      }"><AssignedTo>Agent</AssignedTo></Task>`
    : "";

export default ({
  policyId,
  serviceUrl,
  username,
  note,
  attachments,
  taskProps = {}
}) => {
  const reqOpts = {
    method: "POST",
    headers: {
      Accept: "application/vnd.ics360.insurancepolicy.2.8+xml",
      "Content-Type": "application/xml; schema=policychangeset.2.1",
      "X-Commit": true
    },
    mode: "cors",
    credentials: "include"
  };

  const body = `<PolicyChangeSet schemaVersion="2.1" username="${username}" description="Added via IPC Manager">${createNoteXml(
    taskProps,
    note
  )}${createAttachmentXml(attachments)}\n${createTask(
    taskProps
  )}\n</PolicyChangeSet>`;

  reqOpts.body = body;

  const req = new Request(`${serviceUrl}/policies/${policyId}`, reqOpts);
  return fetch(req).then(res => {
    if (!res.ok) {
      throw new Error("Unable to add note.");
    }
    return res.text();
  });
};
