import { isEmpty } from "lodash";

const getParentInfo = policy => {
  const { bookRollIndicator, identifiers, flags } = policy;

  // Dovetail/Rollover
  if (identifiers?.parentPolicyID) {
    if (isEmpty(bookRollIndicator) || bookRollIndicator === "Grandchild") {
      return {
        id: identifiers.parentPolicyID,
        relationship: "Dovetail"
      };
    }
    return {
      id: identifiers.parentPolicyID,
      relationship: bookRollIndicator
    };
  }

  // Rollover
  if (
    identifiers?.importPolicyId &&
    // bandaid/catch for improper staging values
    !identifiers.importPolicyId.includes(" ")
  ) {
    return {
      id: identifiers.importPolicyId,
      relationship: "Rollover"
    };
  }

  // Cancel/Rewrite
  if (flags?.length > 0) {
    const cancelRewriteFlag = flags.find(
      ({ name, value }) => name === "CancelRewrite" && value
    );
    let id = "";

    if (cancelRewriteFlag) {
      const matcher = /(?=.{0,12})([0-9A-z]{12})/;

      if (cancelRewriteFlag.message.match(matcher)) {
        id = cancelRewriteFlag.message.match(matcher)[0].trim();
      }

      return {
        id,
        relationship: "Cancel/Rewrite"
      };
    }
  }

  return null;
};

const getChildInfo = policy => {
  const { identifiers } = policy;

  // Rollover
  if (identifiers?.childPolicyID) {
    return {
      id: identifiers.childPolicyID,
      relationship: "Rollover"
    };
  }

  return null;
};

export default policy => ({
  parent: getParentInfo(policy),
  child: getChildInfo(policy)
});
