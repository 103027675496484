import { cachePromises as cache } from "../utils";

const CACHE_DURATION = 1000 * 60 * 60 * 4; // 4 hours

const dataItemReducer = (
  acc,
  {
    attributes: {
      name: { value: name },
      value: { value } = { value: "" }
    }
  }
) => ({ ...acc, [name]: value });

const headers = {
  Accept: "application/xml, text/xml, */*; q=0.01"
};

const parseOrg = xml => ({
  name: xml.querySelector("Name").textContent,
  ...Array.from(xml.attributes).reduce(
    (acc, { name, value }) => ({ ...acc, [name]: value }),
    {}
  ),
  ...Array.from(xml.querySelectorAll("Organization > DataItem")).reduce(
    dataItemReducer,
    {}
  ),
  physicalAddress: Array.from(
    xml.querySelectorAll("Address[type='physical'] > *")
  ).reduce(
    (acc, { tagName, textContent }) => ({
      ...acc,
      [tagName]: textContent
    }),
    {}
  ),
  mailingAddress: Array.from(
    xml.querySelectorAll("Address[type='mailing'] > *")
  ).reduce(
    (acc, { tagName, textContent }) => ({
      ...acc,
      [tagName]: textContent
    }),
    {}
  ),
  role: Array.from(
    xml.querySelectorAll("Organization > Role > DataItem")
  ).reduce(dataItemReducer, {}),
  identities: Array.from(
    xml.querySelectorAll("Organization > Affiliation > Identity")
  ).map(identity => {
    const dataItems = Array.from(identity.querySelectorAll("DataItem")).reduce(
      dataItemReducer,
      {}
    );
    return {
      id: identity.id,
      name: identity.querySelector("Name")?.textContent,
      email: identity.querySelector("Email")?.textContent,
      ...dataItems
    };
  })
});

export const getOrg = async ({ ALC, serviceUrl }) => {
  if (!ALC)
    return Promise.reject(
      new Error("Unable to fetch organization data. Missing ALC value.")
    );

  const data = await fetch(`${serviceUrl}/organizations/${ALC}`, {
    method: "GET",
    headers: {
      ...headers
    },
    mode: "cors",
    credentials: "include"
  })
    .then(res => {
      if (!res.ok) throw Error("Unable to retrieve organization xml.");
      return res.text();
    })
    .then(xmlStr => {
      const xml = new window.DOMParser().parseFromString(
        xmlStr,
        "application/xml"
      );
      return parseOrg(xml.querySelector("Organization"));
    });

  return data;
};

export const getOrgs = cache(
  ({ serviceUrl, serviceToken, params = "" }) =>
    fetch(`${serviceUrl}/organizations/${params}`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${serviceToken}`,
        ...headers
      },
      mode: "cors",
      credentials: "include"
    })
      .then(res => {
        if (!res.ok) throw Error("Unable to retrieve organization xml.");
        return res.text();
      })
      .then(xmlStr => {
        const xml = new window.DOMParser().parseFromString(
          xmlStr,
          "application/xml"
        );
        return Array.from(
          xml.querySelectorAll("Organizations > Organization")
        ).map(parseOrg);
      }),
  ({ params }) => params,
  { duration: CACHE_DURATION, max: 10 }
);
