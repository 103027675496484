import PropTypes from "prop-types";
import React from "react";

const ViewWrapper = ({ flag, children }) => {
  if (!flag) {
    return null;
  }
  return <>{children}</>;
};

ViewWrapper.defaultProps = {
  flag: false
};

ViewWrapper.propTypes = {
  flag: PropTypes.bool,
  children: PropTypes.any.isRequired
};

export default ViewWrapper;
