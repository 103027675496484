import formatMoneyValue from "./formatMoneyValue";

const BLOCK_FORMATTER = {
  currency: formatMoneyValue
};

const findBlockFormatter = vocab => {
  const displayFormat =
    (vocab.meta || []).find(({ name }) => name === "DisplayFormat") || {};
  return (
    BLOCK_FORMATTER[(displayFormat.value || "").toLowerCase()] || (val => val)
  );
};

const findEnum = (enums, lookup) => {
  const hasKeys = Object.keys(enums).length > 0;
  const enumVal =
    hasKeys && enums.enumerations.find(item => item.value === lookup);
  return enumVal ? enumVal.label : lookup;
};

// pass in value, and vocab entry for a specific data item
// this will return the formatted display
export default (value, vocab) => {
  const enums = Object.keys(vocab).length > 0 ? vocab.enumerations : {};
  const formatter = findBlockFormatter(vocab);

  return value ? findEnum(enums, formatter(value)) : undefined;
};
