export default () =>
  fetch(`/git-log.txt?v=${__VERSION__}`, {
    headers: {
      "Content-Type": "text/plain",
      Accept: "text/plain"
    }
  })
    .then(res => {
      if (!res.ok) {
        throw new Error("error fetching git-log.txt");
      } else {
        return res.text();
      }
    })
    .then(text => {
      const wrappedText = `[${text.slice(0, text.lastIndexOf(","))}]`;
      return JSON.parse(wrappedText);
    });
