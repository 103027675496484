export default ({ serviceUrl, policyId }) =>
  fetch(
    `${serviceUrl}/cru-4/policies/api/rest/v1/policies/${policyId}/inspection-document`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json"
      }
    }
  ).then(res => {
    if (!res.ok)
      return Promise.reject(
        new Error("Unable to retrieve inspection document.")
      );
    return res.blob();
  });
