/* eslint-disable camelcase */
import { isEmpty } from "lodash";
import PolicyParser from "@package/policy-parser";
import postPolicyNote from "qandp/promises/post-policy-note";
import { getOrg } from "../../../../../promises/get-orgs";

const genXml = ({
  processImmediately,
  username,
  insightPolicyId,
  version,
  effectiveDate,
  agencyLocationCode,
  agencyLocationId,
  agentId
}) => `<TransactionRequest schemaVersion="1.4" type="${
  processImmediately ? `BrokerOfRecordChange` : `PendingBrokerOfRecordChange`
}">
  <Initiation>
    <Initiator type="user">${username}</Initiator>
  </Initiation>
  <Target>
    <Identifiers>
      <Identifier name="InsightPolicyId" value="${insightPolicyId}"/>
    </Identifiers>
    <SourceVersion>${version}</SourceVersion>
  </Target>
  <EffectiveDate>${effectiveDate}</EffectiveDate>
  <${
    processImmediately
      ? `BrokerOfRecordChanges`
      : `PendingBrokerOfRecordChanges`
  }>
    <AgencyLocationCode>${agencyLocationCode}</AgencyLocationCode>
    <AgencyLocationId>${agencyLocationId}</AgencyLocationId>
    <AgentId>${agentId}</AgentId>
  </${
    processImmediately
      ? `BrokerOfRecordChanges`
      : `PendingBrokerOfRecordChanges`
  }>
</TransactionRequest>`;

export default async ({
  id,
  orgServiceUrl,
  serviceUrl,
  serviceToken,
  policy: { version, identifiers, currentTerm },
  commit,
  username,
  values: { processTime, agentId, agencyLocationCode: ALC, note }
}) => {
  const { insightPolicyId } = identifiers || {};
  const { effectiveDate } = currentTerm || {};
  const processImmediately = !isEmpty(processTime)
    ? JSON.parse(processTime)
    : true;

  if (!ALC)
    return Promise.reject(
      new Error("Unable to change broker of record. Missing ALC value.")
    );

  const organization = await getOrg({
    serviceUrl: orgServiceUrl,
    serviceToken,
    ALC
  });

  const {
    role: { agencyLocationCode },
    id: agencyLocationId
  } = organization;

  const body = genXml({
    processImmediately,
    username,
    insightPolicyId,
    version,
    effectiveDate,
    agencyLocationCode,
    agencyLocationId,
    agentId
  });

  const reqOpts = {
    body,
    method: "POST",
    headers: {
      Accept: "application/vnd.ics360.insurancepolicy.2.8+xml",
      "Content-Type": "application/xml; schema=transactionrequest.1.4",
      "X-Commit": commit,
      "X-IPM-Action": "BrokerOfRecord"
    },
    mode: "cors",
    credentials: "include"
  };

  const req = new Request(`${serviceUrl}/policies/${id}`, reqOpts);

  const resp = await fetch(req);
  if (!resp.ok) {
    return Promise.reject(new Error("Unable to change broker of record."));
  }
  if (note) await postPolicyNote({ policyId: id, serviceUrl, username, note });
  const xmlResp = await resp.text();
  return PolicyParser.parse(xmlResp);
};
