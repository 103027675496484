export default ({ policyId, serviceUrl }) => {
  return fetch(`${serviceUrl}/${policyId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Cache-Control": "no-cache"
    },
    credentials: "include"
  }).then(res => {
    if (!res.ok) throw Error("Unable to retrieve data.");
    return res.json();
  });
};
