import React, { useReducer, useContext } from "react";
import PT from "prop-types";
import { QPContext, TabContext } from "..";

const reducer = (id, state, action) => ({
  ...state,
  [id]: {
    ...(state[id] || {}),
    [action.type]: action.payload
  }
});

/*
  This provider allows tabs to store data that can be preserved
  while switching between tabs and policies
  State has structure like
  {
    policyId1: {
      action1: {pieceOfState: 'example'},
      action2: {}
    }
  }
*/

const TabContextProvider = ({ children }) => {
  const {
    policy: { identifiers }
  } = useContext(QPContext);
  const { quoteNumber } = identifiers || {};

  const [state, dispatch] = useReducer(
    (currentState, action) => reducer(quoteNumber, currentState, action),
    {}
  );

  return (
    <TabContext.Provider value={{ state: state[quoteNumber] || {}, dispatch }}>
      {children}
    </TabContext.Provider>
  );
};

TabContextProvider.propTypes = {
  children: PT.element.isRequired
};

export default TabContextProvider;
