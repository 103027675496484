const getPackageTitle = (packageId, analysisApiUrl) =>
  fetch(`${analysisApiUrl}/analysis/package/${packageId}`, {
    method: "GET",
    credentials: "include"
  })
    .then(res => {
      if (!res.ok) throw Error("Unable to retrieve analysis package title.");
      return res.text();
    })
    .then(xmlStr =>
      new window.DOMParser().parseFromString(xmlStr, "application/xml")
    )
    .then(
      xml =>
        xml.evaluate(
          `//RiskAnalysisPackage/Meta/Meta`,
          xml,
          null,
          XPathResult.STRING_TYPE,
          null
        ).stringValue
    )
    .then(title => ({ id: packageId, title }));

export default ({ packageIds, analysisApiUrl }) =>
  fetch(`${analysisApiUrl}/analysis/packages`, {
    method: "GET",
    credentials: "include"
  })
    .then(res => {
      if (!res.ok) throw Error("Unable to retrieve analysis vocabulary.");
      return res.text();
    })
    .then(xhtmlStr =>
      new window.DOMParser().parseFromString(xhtmlStr, "application/xhtml+xml")
    )
    .then(xhtml =>
      Array.from(xhtml.querySelectorAll("ul[criteria] > li > a[id]")).reduce(
        (vocab, node) => ({
          ...vocab,
          [node.id]: node.attributes.getNamedItem("data-label").value
        }),
        {}
      )
    )
    // Check that each package id has a title.  If not, call the detail API for that package id.
    .then(packages =>
      Promise.all(
        Array.from(new Set(Object.keys(packages).concat(packageIds))).map(
          id => {
            if (packages[id]) {
              return Promise.resolve({ id, title: packages[id] });
            }

            return getPackageTitle(id, `${analysisApiUrl}`);
          }
        )
      ).then(titles =>
        titles.reduce(
          (_titles, { id, title }) => ({
            ..._titles,
            [id]: title
          }),
          {}
        )
      )
    );
