export class Cache {
  constructor({ duration, max } = {}) {
    this.duration = duration;
    this.max = max;
    this.now = Date.now;
    this.stack = [];
  }

  getAllUnexpired() {
    const now = this.now();
    return this.duration
      ? this.stack.filter(({ timestamp }) => now - timestamp < this.duration)
      : this.stack;
  }

  retrieve(key) {
    const items = this.getAllUnexpired();
    const index = items.findIndex(c => c.key === key);
    if (index === -1) {
      this.stack = items;
      return null;
    }
    const item = items[index];
    this.stack = [item, ...items.slice(0, index), ...items.slice(index + 1)];
    return item;
  }

  store(key, promise) {
    const item = { key, timestamp: this.now(), promise };
    const next = [item, ...this.getAllUnexpired()];
    this.stack = this.max ? next.slice(0, this.max) : next;
    return promise
      .then(result => {
        item.result = result;
        delete item.promise;
        return result;
      })
      .catch(err => {
        const result = err instanceof Error ? err : new Error(err);
        item.result = result;
        delete item.promise;
        throw result;
      });
  }
}

export default (request, keyResolver, options) => {
  const cache = new Cache(options);
  return (params, fetchPolicy = "cache-first") => {
    const key = keyResolver(params);
    if (fetchPolicy === "cache-only") {
      return cache.retrieve(key);
    }
    if (fetchPolicy === "network-only") {
      const promise = request(params);
      cache.store(key, promise);
      return promise;
    }
    // "cache-first"
    const cached = cache.retrieve(key);
    if (cached) {
      return cached.result instanceof Error
        ? Promise.reject(cached.result)
        : Promise.resolve(cached.result);
    }
    const promise = request(params);
    cache.store(key, promise);
    return promise;
  };
};
