export default async ixlibrary => {
  const req = new Request(
    `${ixlibrary.url}/${
      ixlibrary.basePath
    }/buckets/underwriting/objects/assignee_list.xml`,
    {
      method: "GET",
      credentials: "include"
    }
  );
  const res = await fetch(req);
  const xmlStr = await res.text();
  const xml = new window.DOMParser().parseFromString(xmlStr, "application/xml");
  return Array.from(xml.querySelectorAll("AssigneeList > Assignee")).map(
    ({ attributes }) => ({
      label: attributes.getNamedItem("identity").value,
      value: attributes.getNamedItem("identity").value,
      active: attributes.getNamedItem("active").value === "true"
    })
  );
};
