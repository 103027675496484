// copying directly from insight-central-policies action script
const parseRuleExpression = expression => {
  if (!expression) return { fields: [], flags: [] };
  const result = { fields: [], flags: [] };
  const lookUp = /ff\.(?!flag_)([a-zA-Z0-9_]+)|ff\.flag_(underwritingexception[a-zA-Z0-9_]*)/gi;
  const results = expression.match(lookUp);
  if (!results) return { fields: [], flags: [] };
  let generalExceptionExists = false;

  for (let i = 0; i < results.length; i += 1) {
    // clean up the field name by removing any "ff." or "ff.flag_" prefixes
    const fieldName = String(results[i]).replace(/ff\.flag_|ff\./gi, "");

    // depending on the prefix we either have a field or flag, also make sure that field or flag
    // isn't already part of the result collection, we don't want multiples of the same name
    if (
      fieldName.toLowerCase().indexOf("underwritingexception") === -1 &&
      result.fields.indexOf(fieldName) === -1
    ) {
      // this is a field, for example: LossHistory, ConstructionYear, etc.
      result.fields.push(fieldName);
    } else if (fieldName.toLowerCase() === "underwritingexception") {
      // this is considered the general exception flag, if its defined
      // and no other flags are found (e.g. underwritingexceptionLossHistory)
      // then at a minimum we need to add this general exception to the list
      generalExceptionExists = true;
    } else if (
      fieldName.toLowerCase().indexOf("underwritingexception") >= 0 &&
      result.flags.indexOf(fieldName) === -1
    ) {
      // this is what we refer to as a flag, for example:
      // underwritingexceptionLossHistory, underwritingexceptionCoverageA, etc.
      result.flags.push(fieldName);
    }
  }

  // we found a general exception flag but no specific ones such as
  // underwritingexceptionLossHistory so then we use the general flag only,
  // this is an important part to determining whether a block has both the
  // option of approving and declining, only in the case of 0 flags found
  // (meaning no flag starting with "underwritingException" whatsoever)
  // is only the DECLINE option available
  if (generalExceptionExists && result.flags.length === 0) {
    result.flags.push("underwritingException");
  }

  return result;
};

const ruleAttribute = (ruleset, attributeName) => {
  const attr = ruleset.querySelector(`rule > ${attributeName}`) || {};
  return attr.textContent;
};

const parseRulesets = xml =>
  xml.map(ruleset => ({
    id: ruleset.attributes.id.value,
    tag: ruleset.attributes.tag.value,
    name: ruleAttribute(ruleset, "name"),
    errorDescription: ruleAttribute(ruleset, "errorDescription"),
    expressionResult: parseRuleExpression(ruleAttribute(ruleset, "expression"))
  }));

export default async ({ serviceUrl }) => {
  const res = await fetch(`${serviceUrl}/ruleset_xml.cfm?export=true`, {
    method: "POST",
    mode: "cors",
    credentials: "include"
  });

  if (!res.ok) throw Error("Unable to retrieve ruleset xml");
  const xmlStr = await res.text();
  const xml = new window.DOMParser().parseFromString(xmlStr, "application/xml");
  const filteredXml = Array.from(xml.querySelectorAll("ruleset"));
  return parseRulesets(filteredXml);
};
