export var MOD_ID = "producer-engagement";
export var MOD_BASEPATH = "/".concat(MOD_ID);
export var producerItemDisplayText = {
    parentProducerName: "Agency Name",
    affilitationCode: "Distribution Group",
    producerCommissionStatusCode: "Engagement Status",
    parentProducerPifCount: "Policies In Force",
    agencyLocationCode: "Agency Location Code",
    daysContractedCount: "Days Contracted",
    isExemptReducedCommission: "Exempt Reduced Commission",
    id: "Id",
    quarterId: "Quarter Id",
    processDate: "Process Date",
    parentProducerId: "Parent Producer Id",
    contractedDate: "Contracted Date",
    producerActivePolicyCount: "Producer Active Policy Count",
    firstDayOFQuarter: "First Day Of Quarter",
    lastDayOFQuarter: "Last Day Of Quarter",
    mailingAddressProvinceCode: "Mailing Address Province Code",
    statusCode: "Status Code",
    bonusCommissionEligibleProducerCode: "Bonus Commission Eligible Producer Code"
};
export var TABLE_HEADERS = [
    {
        type: "normal",
        value: "Agency Name",
        key: "parentProducerName",
        sortBy: ["asc", "desc"]
    },
    {
        type: "normal",
        value: "Distribution Group",
        key: "affilitationCode",
        sortBy: ["asc", "desc"]
    },
    {
        type: "dropdown",
        value: "Engagement Status",
        key: "producerCommissionStatusCode",
        sortBy: ["commission reduced", "disengaged", "engaged"]
    },
    {
        type: "normal",
        value: "Policies In Force",
        key: "parentProducerPifCount",
        sortBy: ["asc", "desc"]
    },
    {
        type: "normal",
        value: "Agency Location Code",
        key: "agencyLocationCode",
        sortBy: ["asc", "desc"]
    },
    {
        type: "normal",
        value: "Days Contracted",
        key: "daysContractedCount",
        sortBy: ["asc", "desc"]
    }
];
export var INITIAL_SORT_CONFIG = {
    column: "producerCommissionStatusCode",
    direction: "commission reduced"
};
export var SELECT_SEARCH_OPTIONS = [
    { label: "Distribution Group", value: "1" },
    { label: "Engagement Status", value: "2" },
    { label: "Agency Location Code", value: "3" },
    { label: "All", value: "1,2,3" }
];
export var INITIAL_SEARCH_CONFIG = {
    input: "",
    options: SELECT_SEARCH_OPTIONS
};
export var REQUEST_ERROR_MESSAGE = "We're sorry, something went wrong on our end. Please try again later.";
export var EMPTY_RESPONSE_MESSAGE = "No results found";
export var FILTERBAR_TEXT = "Search for match in Distribution Group, Engagement Status or Agency Location Code";
export var HEADER_TOOLTIP_TEXTS = {
    engaged: "Enrolled less than two years. Enrolled two years or more with 20 or more total active inforce policies.",
    disengaged: "Enrolled two years or more with less than 20 total inforce active policies and is out of scope to have reduced commissions.",
    "commission reduced": "Enrolled two years or more with less than 20 total inforce active policies and is in scope to have reduced commissions."
};
